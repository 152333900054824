const ServicesIcon=(props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        if(click)click(e);
    }

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.671 5.00921C13.671 6.67115 12.3502 8.01842 10.7208 8.01842C9.09137 8.01842 7.77049 6.67115 7.77049 5.00921C7.77049 3.34727 9.09137 2 10.7208 2C12.3502 2 13.671 3.34727 13.671 5.00921ZM12.3104 3.86515C12.4207 3.97295 12.4245 4.15154 12.3188 4.26403L10.6765 6.01198C10.5031 6.19653 10.2169 6.20598 10.0321 6.03325L9.24312 5.29554C9.13052 5.19025 9.12291 5.0118 9.22613 4.89695C9.32935 4.78209 9.50431 4.77433 9.61692 4.87962L10.3395 5.55518L11.9194 3.87364C12.0251 3.76115 12.2002 3.75735 12.3104 3.86515Z" />
            <path d="M7.55893 9.75286C7.47268 9.72812 7.38076 9.7644 7.3333 9.84192C7.26619 9.95153 7.31366 10.0964 7.43189 10.1428L8.66996 10.6289C9.03981 10.7741 9.45446 10.7388 9.79562 10.5331L13.0589 8.56552C13.3145 8.41139 13.6412 8.46245 13.8398 8.68758C14.0962 8.97813 14.0375 9.43357 13.7161 9.64657L10.3356 11.8874C9.46776 12.4626 8.36792 12.5319 7.43727 12.0701L5.83176 11.2735C5.63938 11.178 5.41397 11.1815 5.22454 11.2829C4.90289 11.4551 4.5053 11.3359 4.3262 11.0135L3.26552 9.10436C3.16431 8.92219 3.23177 8.69074 3.4141 8.59455L4.57875 7.98011C5.32629 7.58573 6.20338 7.53576 6.98933 7.84277L9.36009 8.76886C9.75026 8.92127 9.92756 9.38278 9.74278 9.76498C9.59157 10.0777 9.24323 10.2361 8.91412 10.1417L7.55893 9.75286Z" />
            <path d="M1.51309 9.20555C1.07089 9.37469 0.871887 9.89905 1.08718 10.3278L2.20117 12.5462C2.42386 12.9896 2.97568 13.1362 3.38213 12.8598C3.73343 12.6209 3.84857 12.1484 3.6478 11.7696L2.48582 9.577C2.29807 9.22273 1.88278 9.06415 1.51309 9.20555Z" />
        </svg>
    )
}

export default ServicesIcon;