import './Icon.css';
const ColorsIcon = (props)=>{

    const {click, size, style} = props;

    const handleClick =(e)=>{
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"  className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""} ${style!==undefined?style:""}`} onClick={(e)=>handleClick(e)}>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.17659 13.5444C1.29737 11.4093 1.80412 7.79248 2.31088 5.7099C2.82643 3.59124 4.88849 2.00068 7.46612 2.00003C10.0437 1.99938 10.5593 2.00003 12.1058 3.05949C13.6253 4.10039 15.1448 8.20924 12.7531 9.86001C12.6676 9.919 12.5752 9.96985 12.4808 10.0131C11.9911 10.2376 10.3182 10.9228 9.01269 10.4757C7.46611 9.94594 7.46612 11.5351 9.01269 13.1243C10.3153 14.4628 7.59528 13.9224 6.70458 13.7186C6.52447 13.6774 6.34586 13.6185 6.17659 13.5444ZM10.0437 4.50682C10.0437 5.09194 9.58212 5.56628 9.01269 5.56628C8.44326 5.56628 7.98164 5.09194 7.98164 4.50682C7.98164 3.9217 8.44326 3.44736 9.01269 3.44736C9.58212 3.44736 10.0437 3.9217 10.0437 4.50682ZM5.40402 6.23774C5.97345 6.23774 6.43507 5.76341 6.43507 5.17828C6.43507 4.59316 5.97345 4.11883 5.40402 4.11883C4.83458 4.11883 4.37297 4.59316 4.37297 5.17828C4.37297 5.76341 4.83458 6.23774 5.40402 6.23774ZM3.85744 9.41611C4.42688 9.41611 4.88849 8.94178 4.88849 8.35665C4.88849 7.77153 4.42688 7.29719 3.85744 7.29719C3.28801 7.29719 2.82639 7.77153 2.82639 8.35665C2.82639 8.94178 3.28801 9.41611 3.85744 9.41611ZM11.5903 7.82693C12.1597 7.82693 12.6214 7.35259 12.6214 6.76747C12.6214 6.18235 12.1597 5.70801 11.5903 5.70801C11.0209 5.70801 10.5593 6.18235 10.5593 6.76747C10.5593 7.35259 11.0209 7.82693 11.5903 7.82693Z"/>
        </svg>
    )
}

export default ColorsIcon;

