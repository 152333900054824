import React, { useContext, useState } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';
import SlideInElementBottom from '../../../util/SlideInElementBottom';
import { useParams, useNavigate   } from 'react-router-dom';
import TopBar from './TopBar';
import SubProyecto from './SubProyecto';

const ProyectosPage = ()=>{
    const { seccionesData } = useContext(InputValuesContext);
    const [preview,setPreview] = useState("Prod");
    const { paramName } = useParams();
    const navigate  = useNavigate ();

    const goToProyectoWithParam = (index) => {
        navigate (`/circlevibe/proyecto/${index}`);
      };

    return (
        <div id="CircleVibeProyectoPage" className='pageContainer' style={{
            background: `${seccionesData.Config.mode==="light"? "#f8f8f8":"#101010"}`,
          }}>
            <TopBar color={`${seccionesData.Config.mode==="light"? "#f8f8f8":"#101010"}`}
                    logoString={seccionesData.Config.logo}
                    mode={seccionesData.Config.mode}></TopBar>
            {paramName==='all'?(
                <div  className={`generalContainer${preview ? preview : 'Prod'}`} >
                    <div className={`col-m12${preview ? preview : 'Prod'} center`}>
                        <div className={`col-m12${preview ? preview : 'Prod'} center`}>
                            <SlideInElementBottom>
                                <div className='galeriaGeneralCircleVibe center'>
                                    <div className={`col-m8${preview ? preview : 'Prod'} center`}>
                                        <div className='title'
                                            style={{color: seccionesData.Config.color}}
                                        >{seccionesData.Proyectos.titulo}</div>
                                        <div className='body'>{seccionesData.Proyectos.descripcion===''?seccionesData.Proyectos.descripcionPreview:seccionesData.Proyectos.descripcion}</div>
                                    </div>
                                </div>
                            </SlideInElementBottom>
                        </div>    
                        {seccionesData.Proyectos.proyectos.length<1?(
                            <div className={`row-m12${preview ? preview : 'Prod'} center gap32 wrap-row `}>
                                {seccionesData.Proyectos.proyectosPreview.map((item, index) => (
                                    <div key={index} className={`col-m12${preview ? preview : 'Prod'} center`}>
                                        <SlideInElementBottom>
                                            {index % 2 === 0 ?(
                                                <div className={`center ${preview!=="Mobile" ? 'row' : 'col'} casoEstudioCardCircleVibe`}>
                                                    <div className={`col-m6${preview ? preview : 'Prod'}`}>
                                                        {item.imagen!=="" && (
                                                            <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={item.imagen} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                                                        )}
                                                    </div>
                                                    <div className={`col-m6${preview ? preview : 'Prod'} center`}>
                                                        <div className='subtitle'>{item.titulo}</div>
                                                        <div className='body' >{item.descripcion}</div>
                                                    </div>
                                                </div>
                                            ):(
                                                <div className={`center ${preview!=="Mobile" ? 'row' : 'col'} casoEstudioCardCircleVibe`}>
                                                    <div className={`col-m6${preview ? preview : 'Prod'} center`}>
                                                        <div className='subtitle'>{item.titulo}</div>
                                                        <div className='body' >{item.descripcion}</div>
                                                    </div>
                                                    <div className={`col-m6${preview ? preview : 'Prod'}`}>
                                                        {item.imagen!=="" && (
                                                            <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={item.imagen} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </SlideInElementBottom>
                                    </div>
                                ))}
                            </div>
                        ):(
                            <div className={`row-m12${preview ? preview : 'Prod'} center gap32 wrap-row`}>
                                {seccionesData.Proyectos.proyectos.map((item, index) => (
                                    <div key={index} className={`col-m12${preview ? preview : 'Prod'} center`}>
                                        <SlideInElementBottom>
                                            <div className={`center ${preview!=="Mobile" ? 'row' : 'col'} casoEstudioCardCircleVibe`} onClick={()=>goToProyectoWithParam(index)}>
                                                <div className={`col-m6${preview ? preview : 'Prod'}`}>
                                                    {item.imagen!=="" && (
                                                        <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={item.imagen} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                                                    )}
                                                </div>
                                                <div className={`col-m6${preview ? preview : 'Prod'} center`}>
                                                    <div className='subtitle'>{item.titulo}</div>
                                                    <div className='body' >{item.descripcion}</div>
                                                </div>
                                            </div>
                                        </SlideInElementBottom>
                                    </div>
                                ))}
                            </div>
                        )} 
                    </div>
                </div>
            ):(
                <SubProyecto subProyectoIndex={paramName} preview={preview}></SubProyecto>
            )}
        </div>
    )
}

export default ProyectosPage;