import './Icon.css';
const PlusIcon = (props)=>{

    const {click, size, style} = props;

    const handleClick =(e)=>{
        if(click)click(e);
    }
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""} ${style!==undefined?style:""}`} onClick={(e)=>handleClick(e)}>
            <mask id="mask0_615_804" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <rect width="16" height="16"/>
            </mask>
            <g mask="url(#mask0_615_804)">
            <path d="M7.33325 12.6663V8.66634H3.33325V7.33301H7.33325V3.33301H8.66658V7.33301H12.6666V8.66634H8.66658V12.6663H7.33325Z"/>
            </g>
        </svg>
    )
}

export default PlusIcon;