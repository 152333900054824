import React, { useRef } from 'react';
import './GeneralForm.css';


const GeneralForm = (props)=>{

    const {close, children : Children} = props;
    const contentRef = useRef();

    const handleCloseOutside = (event)=>{
        if (contentRef.current && !contentRef.current.contains(event.target)) {
            close(false);
        }
    }

    return (
        <div className={`generalFormContainer`} onClick={handleCloseOutside}>
            <div ref={contentRef} className={`generalFormContent`}>
                <div  style={{position : "relative"}}>
                    <div className='clickeable' onClick={()=>close(false)} style={{position : "absolute", top:"2%", right:"0%"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <mask id="mask0_1222_5593" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                <rect width="24" height="24" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_1222_5593)">
                                <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#6E6E6E"/>
                            </g>
                        </svg>
                    </div>
                </div>
                {Children && <Children></Children>}
                <span className='caption centerText'>© 2023 ProfolioTeam.</span>
            </div>
        </div>
    )
}

export default GeneralForm;