import './../css/Footer.css';
import logo from '../assets/icons/profolio-logotipo-footer.svg';
import facebook from '../assets/icons/Facebook.svg';
import instagram from '../assets/icons/Instagram.svg';
import pinterest from '../assets/icons/Pinterest.svg';
import linkedin from '../assets/icons/LinkedIn.svg';

const Footer = () => {
  return (
    <div id="footer" className='container-footer'>
      <div className='container-footer1'>
        <div className='col-m12 center'>
          <div className='row'>
            <div className='contenido-max'>
              <div className='col left'>
                <img src={logo} alt='' className='logo-footer p16'></img>
                <p className='subtitle_1 p16'>Tu talento, tu pasión, tu portafolio.</p>
                <div className='row left'>
                  <img src={facebook} alt='' className='redes-icon'></img>
                  <img src={instagram} alt='' className='redes-icon'></img>
                  <img src={pinterest} alt='' className='redes-icon'></img>
                  <img src={linkedin} alt='' className='redes-icon'></img>
                </div>
              </div>
            </div>
            <div className='contenido-min'>
              <div className='col left'>
                <h6 className='heading_6 p8'>Páginas</h6>
                <p className='subtitle_1 p8'>Inicio</p>
                <p className='subtitle_1 p8'>Sobre Nosotros</p>
                <p className='subtitle_1 p8'>Portafolios Publicados</p>
                <p className='subtitle_1 p8'>Libreria de Plantillas</p>
                <p className='subtitle_1 p8'>Crear Cuenta</p>
              </div>
            </div>
            <div className='contenido-min'>
              <div className='col left'>
                <h6 className='heading_6 p8'>Políticas</h6>
                <p className='subtitle_1 p8'>Términos y Condiciones</p>
                <p className='subtitle_1 p8'>Política de Cookies</p>
                <p className='subtitle_1 p8'>Políticas de Privacidad</p>
                <p className='subtitle_1 p8'>Políticas de Cancelación</p>
              </div>
            </div>
            <div className='contenido-min'>
              <div className='col left'>
                <h6 className='heading_6 p8'>Ayuda</h6>
                <p className='subtitle_1 p8'>Preguntas Frecuentes</p>
                <p className='subtitle_1 p8'>Contáctanos</p>
                <p className='subtitle_1 p8'>Soporte Técnico</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-footer2'>
        <span className='caption'>© 2023 ProfolioTeam. Todos los derechos reservados.</span>
      </div>
    </div>
  );
};

export default Footer;