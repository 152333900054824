import './../Icon.css';
const ArticleIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.2722 26.1024C11.8574 24.7368 13.6368 24.3941 14.6873 25.4447L19.434 30.1914C20.4845 31.2419 20.1419 33.0213 18.7763 33.6065L10.4696 37.1665C8.72718 37.9133 6.96541 36.1515 7.71216 34.4091L11.2722 26.1024ZM4 42.3787C4 41.5503 4.67157 40.8787 5.5 40.8787H41.5C42.3284 40.8787 43 41.5503 43 42.3787C43 43.2071 42.3284 43.8787 41.5 43.8787H5.5C4.67157 43.8787 4 43.2071 4 42.3787Z"/>
            <path d="M20.9393 29.318L15.5607 23.9393C14.9749 23.3536 14.9749 22.4038 15.5607 21.818L32.9393 4.43934C33.5251 3.85355 34.4749 3.85355 35.0607 4.43934L40.4393 9.81802C41.0251 10.4038 41.0251 11.3536 40.4393 11.9393L23.0607 29.318C22.4749 29.9038 21.5251 29.9038 20.9393 29.318Z" />
        </svg>
    )
}

export default ArticleIcon;



