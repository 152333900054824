import React, { useContext, useEffect, useState } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';

const SubCaso = (props)=>{
    const { seccionesData } = useContext(InputValuesContext);
    const { preview,color,subCasoIndex} = props;
    const [dontShow, setDontShow] = useState([]);

    useEffect(() => {
        let base = [];
        let element = seccionesData.Casos.casos[subCasoIndex].bloques[2].innerlista;
    
        if (element && element.length > 0) {
            element.forEach(subelement => {
                base.push(subelement);
            });
        }
    
        setDontShow(base);
    }, [seccionesData.Casos.casos[subCasoIndex].bloques[2].innerlista]);

    return (
        <div id="CircleVibeSubCaso" className={`generalContainer${preview ? preview : 'Prod'} gap32`}  style={{ minHeight : "calc(100vh - 204px)"}}>
            <div className={`col-m12${preview ? preview : 'Prod'} gap12`}>
                <div className='titleLarge' style={{color: color}}>{seccionesData.Casos.casos[subCasoIndex].titulo}</div>
                <div className='body'>{seccionesData.Casos.casos[subCasoIndex].descripcion}</div>
                {seccionesData.Casos.casos[subCasoIndex].imagen!=="" && (
                    <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={seccionesData.Casos.casos[subCasoIndex].imagen} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                )}
            </div>
            <div className={`col-m12${preview ? preview : 'Prod'} gap12`}>
                {seccionesData.Casos.casos[subCasoIndex].bloques.map((item,index)=>(
                    <div key={index}>
                        {item.innerlista && !item.innerlista.includes(index) && 
                            <div className={`col-m12${preview ? preview : 'Prod'} gap12`}>
                                <div>
                                    <div className='title' style={{color: color}}>{item.titulo.value}</div>
                                    <div className='subtitle' style={{color: color}}>{item.subtitulo.value}</div>
                                    <div className='body'>{item.descripcion.value}</div>
                                    {item.imagen.value!=="" && (
                                        <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={item.imagen.value} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                                    )}
                                    {item.lista.value!=="" && 
                                        <ul>
                                            {item.lista.value.split('\n').map((subitem, index) => (
                                                <li key={index} className='cta'>{subitem}</li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                                {item.innerlista.map((subitem,subindex)=>(
                                    <div key={subindex}>
                                        <div className='title' style={{color: color}}>{seccionesData.Casos.casos[subCasoIndex].bloques[subitem].titulo.value}</div>
                                        <div className='body'>{seccionesData.Casos.casos[subCasoIndex].bloques[subitem].descripcion.value}</div>
                                        {seccionesData.Casos.casos[subCasoIndex].bloques[subitem].imagen.value!=="" && (
                                            <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={seccionesData.Casos.casos[subCasoIndex].bloques[subitem].imagen.value} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                                        )}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SubCaso;