import React, { createContext, useState } from 'react';

export const InputValuesContext = createContext();

export const InputValuesProvider = ({ children }) => {
    
    const [seccionesData, setSeccionesData] = useState({
        Banner :{titulo:'',subtitulo:'',descripcion:'',imagenUrl:'https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png',
          tituloPreview:'Hi, I’m Alessia Parker', subtituloPreview:'Professional Photographer',descripcionPreview:'Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius.'},
        SobreMi: {titulo:'Sobre mí',descripcion:'',
          descripcionPreview:'Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget.'},
        Servicios: {titulo:'Mis servicios',descripcion:'',servicios:[],
          descripcionPreview:'Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget.}',
          serviciosPreview:[{servicio : "Servicio 1", detalle:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius."},
                            {servicio : "Servicio 2", detalle:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius."},
                            {servicio : "Servicio 3", detalle:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius."}]},
        Experiencia: {titulo:'Mi trayectoria profesional',experiencias:[],
          experienciasPreview:[{cargo:"Job Title",nombre:"ENTERPRISE NAME",ubicacion:"",mesInicio:"Ene.",anioInicio:"2022",
          mesFin:"Abr.",anioFin:"2022",descripcion:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. }"}]},
        Educacion: {titulo:'Mi formación académica',educaciones:[],
        educacionesPreview:[{programa:"Programa",institucion:"Institucion",mesInicio:"Ene.",anioInicio:"2022",mesFin:"Abr.",anioFin:"2022",descripcion:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget.}"}]},
        Habilidad: {titulo:'Mi conjunto de habilidades',habilidades:[],descripcion:'',descripcionPreview:'Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames.',
        habilidadesPreview:[{habilidad:"Habilidad 1",detalle:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. "},
                            {habilidad:"Habilidad 2",detalle:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. "},
                            {habilidad:"Habilidad 3",detalle:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. "},
                            {habilidad:"Habilidad 4",detalle:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. "},
                            {habilidad:"Habilidad 5",detalle:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. "}]},
        Contacto: {titulo:'Contáctame',descripcion:'',contactos:[],formContacto:false,
          descripcionPreview:'Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget.}',
          contactosPreview:[{tipo:"instagram",valor:"https://www.instagram.com/"},
                            {tipo:"Facebook",valor:"https://www.facebook.com/"},
                            {tipo:"Instagram",valor:"https://www.instagram.com/"},
                            {tipo:"Pinterest",valor:"https://www.pinterest.ca/"}]},
        Testimonio: {titulo:'Experiencia de clientes',testimonios:[],descripcion:"",descripcionPreview:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget.",
          testimoniosPreview:[{testimonio:"1Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. ",nombre:"Name",cargo:"Gerente"},
                              {testimonio:"2Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. ",nombre:"Name",cargo:"Gerente"},
                              {testimonio:"3Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. ",nombre:"Name",cargo:"Gerente"},
                              {testimonio:"4Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. ",nombre:"Name",cargo:"Gerente"},
                              {testimonio:"5Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius. Elit bibendum venenatis semper volutpat massa venenatis eget. ",nombre:"Name",cargo:"Gerente"}]},
        Cita: {cita:"", autor:"",citaPreview:"orem ipsum dolor sit amet consectetur.", autorPreview:"orem ipsum dolor sit amet consectetur."},
        Galeria: {titulo:"Galería", descripcion:"", galerias:[], descripcionPreview:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius.",
                          galeriaPreview : [{imagenes:["https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png"],titulo:"Imagen1",descripcion:"Descripción"},
                                          {imagenes:["https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png"],titulo:"Imagen2",descripcion:"Descripción"},
                                          {imagenes:["https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png"],titulo:"Imagen3",descripcion:"Descripción"}]},
        Proyectos: {titulo:"Proyectos", descripcion:"", descripcionPreview:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius.",proyectos:[],
                        proyectosPreview : [{imagen:"https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png",titulo:"Imagen1",descripcion:"Descripción"},
                                          {imagen:"https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png",titulo:"Imagen2",descripcion:"Descripción"},
                                          {imagen:"https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png",titulo:"Imagen3",descripcion:"Descripción"}]},
        Casos: {titulo:"Mi portafolio", descripcion:"", descripcionPreview:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius.",casos:[],
                            casosPreview : [{imagen:"https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png",titulo:"Imagen1",descripcion:"Descripción"},
                                          {imagen:"https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png",titulo:"Imagen2",descripcion:"Descripción"},
                                          {imagen:"https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png",titulo:"Imagen3",descripcion:"Descripción"}]},
        Articulo: {titulo:"Mis artículos", descripcion:"",articulos:[], descripcionPreview:"Lorem ipsum dolor sit amet consectetur. Viverra aliquam purus sit hac fames varius.",
                        articulosPreview : [{imagen:"https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png",titulo:"Imagen1",descripcion:"Descripción"},
                                          {imagen:"https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png",titulo:"Imagen2",descripcion:"Descripción"},
                                          {imagen:"https://profolio-dev-data.s3.amazonaws.com/bannerModernVibe.png",titulo:"Imagen3",descripcion:"Descripción"}]},
        Config:{changing:"",color:{primary:"",secondary:"",textColor:""}, tipografia:{titulos:"",cuerpo:""},mode:"light",logo:""},
        inputs : {Banner:true,SobreMi: false,Servicios: false,Experiencia: false,Educacion: false, Habilidades: false,Contacto: true,Testimonios: false,Cita: false,Galeria: false,Proyectos: false,Casos: false,Articulo: false }
    })

    const setGeneral =(seccion, key, value)=>{
        const upd = {...seccionesData};
        upd[seccion][key]=value;
        setSeccionesData(upd);
    }

    const setInnerFormInput =(seccion, key,index,subKey, value)=>{
      const upd = {...seccionesData};
      upd[seccion][key][index][subKey]=value;
      setSeccionesData(upd);
    }

    return (
      <InputValuesContext.Provider value={{ seccionesData, setGeneral,setInnerFormInput }}>
        {children}
      </InputValuesContext.Provider>
    );
  };