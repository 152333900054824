import './Icon.css';
const RightArrowIcon =(props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        if(click)click(e);
    }
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <mask id="mask0_900_2531" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
                <rect y="0.5" width="16" height="16" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_900_2531)">
                <path d="M11.3471 9.2793H3.23047V7.94596H11.3471L7.6138 4.21263L8.5638 3.2793L13.8971 8.61263L8.5638 13.946L7.6138 13.0126L11.3471 9.2793Z"/>
            </g>
        </svg>
    )
}

export default RightArrowIcon;