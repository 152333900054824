import './Icon.css';
const RightChevronIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        if(click)click(e);
    }
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.00011 4.93933L10.1769 8.11611C10.665 8.60426 10.665 9.39572 10.1769 9.88387L7.00011 13.0607L5.93945 12L8.93945 8.99999L5.93945 5.99999L7.00011 4.93933Z" />
        </svg>
    )
}

export default RightChevronIcon;