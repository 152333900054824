import React, { useEffect, useState } from 'react';
import "./MvpForm.css";
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import RadioButtonContainer from '../../RadioButton/RadioButtonContainer';

const MvpForm = (props)=>{

    const {close} = props;
    const [data, setData] = useState({1:"",2:"",3:"",4:"",5:"",6:""})
    const [isAllCompleted, setIsAllCompleted] = useState(false);
    const [radioButtonSelected, setRadioButtonSelected] = useState(-1);

    useEffect(() => {
        if (data[1] !== "" && data[2] !== "" && data[3] !== "" && data[4] !== "" && data[5] !== "" && data[6] !== "")setIsAllCompleted(true)
        else setIsAllCompleted(false)
    }, [data]);

    
    const handleClick = (index,value) => {
        setRadioButtonSelected(index);
        setData({...data,"5":value})
    };

    return (
        <div className='mvpFormaContainer'>
            <h5 className='heading_5'>Formulario de Retro alimentación</h5>
            <p className='body_s'>Antes de descargar tu portafolio y como parte de nuestra versión Beta, tu opinión es crucial para mejorar Profolio. Por favor, comparte tus pensamientos sinceros al completar el siguiente formulario.</p>
            <div className='mvpFormaInputContainer'>
                <Input name={"1"}
                    label={"1. ¿Cómo te llamas y a qué te dedicas?"}
                    value={data[1]}
                    changer={(e)=>setData({...data,"1":e.target.value})}
                />
                <Input name={"2"}
                    label={"2. ¿Cuál fue el bloque más valioso para resaltar tu experiencia y habilidades en tu portafolio?"}
                    value={data[2]}
                    changer={(e)=>setData({...data,"2":e.target.value})}
                />
                <Input name={"3"}
                    label={"3. ¿Hubo algún bloque que te resultara confuso o difícil de utilizar?"}
                    value={data[3]}
                    changer={(e)=>setData({...data,"3":e.target.value})}
                />
                <Input name={"4"}
                    label={"4. ¿Agregarías algún bloque adicional para mejorar la representación de tu trabajo?"}
                    value={data[4]}
                    changer={(e)=>setData({...data,"4":e.target.value})}
                />
                <div>
                    <div className='inputAutoSizeText'>5. En una escala del 1 al 10, ¿cómo calificarías la facilidad de uso de Profolio? [Escoge un número]</div>
                    <RadioButtonContainer clickRadioButton={handleClick} isRow={true} type={2} selected={radioButtonSelected} options={
                        [{key:0,value:"1"},
                        {key:1,value:"2"},
                        {key:2,value:"3"},
                        {key:3,value:"4"},
                        {key:4,value:"5"},
                        {key:5,value:"6"},
                        {key:6,value:"7"},
                        {key:7,value:"8"},
                        {key:8,value:"9"},
                        {key:9,value:"10"}]}>
                    </RadioButtonContainer>
                </div>
                <Input name={"6"}
                    label={"6. ¿Te sentiste limitado/a en algún aspecto al utilizar Profolio? ¿Cómo podríamos mejorar?"}
                    value={data[6]}
                    changer={(e)=>setData({...data,"6":e.target.value})}
                />
                <span className='caption'>En Profolio, tu privacidad es nuestra prioridad La información recopilada se limita a tus respuestas en este formulario, utilizada exclusivamente para mejorar la plataforma. Tus datos no se comparten con terceros, garantizando un uso responsable y confidencial.</span>
            </div>
            <div className='mvpFormaButtonContainer'>
                <Button texto={"Regresar"} tipo={2} click={()=>close(false)}></Button>
                <Button texto={"Descargar"} tipo={1} disabled={!isAllCompleted} click={()=>close(true)}></Button>
            </div>
        </div>
    )
}

export default MvpForm;