import React, { useEffect, useRef, useState } from 'react';
import './Selector.css';
import ClickOutsideClose from '../../util/functions/General/ClickOutsideClose';

const Selector = (props)=>{

    const {options, handleHover, close, handleClose, row, type} = props;
    const contentRef = useRef(null);
    ClickOutsideClose(contentRef,close);

    const [iconList, setIconList] = useState([]);

    useEffect(() => {
        const getIcons = async () => {
            const newIconList = await Promise.all(
                options.map(async (option) => {
                    if(option.icon !== undefined && option.icon!==null && option.icon !==""){
                        const { default: NewComponent } = await import(`./../../util/constants/icons/${option.icon}`);
                        return { component: <NewComponent size={"s"} style={option.text==="Eliminar"?"error":undefined}/> };
                    }else{
                        return { component: null}
                    }
                })
            );
      
            setIconList(newIconList);
        };
        getIcons();
    }, [options]);

    useEffect(() => {
    }, [contentRef]);

    const containerStyle  = {
        maxHeight: `calc(36px * ${row})`,
        width: contentRef.current? (contentRef.current.parentElement).offsetWidth-15:"",
        overflowY: "auto",
        scrollbarWidth: "thin",
        paddingRight: "5px",
        transform: `translate( ${type===3?"0px":"-13px"}, ${type===3?"40px": `calc(36px + (36px * ${(row)/2}))` })`,
        flexDirection: "column"
    };

    const optionStyle={
        width : "calc(100% - 32px)"
    }

    return (
        <div className='selectorContainer'  ref={contentRef}
            onMouseEnter={() => {if(handleHover)handleHover(true)}}
            onMouseLeave={() => {if(handleHover)handleHover(false)}}
            style={type!==1?containerStyle:null}>
                    {options.map((item,index) => (
                        <div key={index} className='selectorOption clickeable' style={type!==1?optionStyle:null} onClick={(e)=>{e.stopPropagation();handleClose(item.text)}}>
                            {iconList[index] && iconList[index].component}
                            <p className={`subtitle_1 ${item.text==="Eliminar"?"error":""}`}>{item.text}</p>
                        </div>
                    ))}
        </div>
    )
}

export default Selector;