import React, { useState } from 'react';
import './Login.css';
import ProfolioIcon from '../../../util/constants/icons/ProfolioIcon';
import Input from '../../../general-components/Input/Input';
import Button from '../../../general-components/Button/Button';
import { useNavigate } from 'react-router-dom';

const Login =()=>{

    const navigate = useNavigate ();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [areValid, setAreValid] = useState(true);

    const validLoginValuesAndGo =()=>{
        if (username === "" && password === ""){
            setAreValid(true);
            navigate("/formulario", { state: { portafolio: "ModernVibe", inf: true } });
        }else{
            setAreValid(false);
        }
    }

    return (
        <div className='loginContainer'>
            <div className='loginCardContainer'>
                <ProfolioIcon></ProfolioIcon>
                <div className='loginFormContainer'>
                    <div className='col gap8'>
                        <h5 className='heading_5'>¡Hola! Es un gusto tenerte aquí</h5>
                        <p className='subtitle_1'>Ingresa tu usuario y contraseña para acceder a tu proyecto.</p>
                    </div>
                    <div className='col-m12 gap16'>
                        <div className='col-m12'>
                            <Input name={"username"}
                                label={"Usuario"}
                                value={username}
                                error={!areValid}
                                changer={(event)=>setUsername(event.target.value)}
                            />
                        </div>
                        <div className='col-m12'>
                            <Input name={"password"}
                                label={"Contraseña"}
                                value={password}
                                error={!areValid}
                                changer={(event)=>setPassword(event.target.value)}
                            />
                        </div>
                        {!areValid &&
                            <span className='caption error'>Usuario y/o contraseña incorrectos.</span>
                        }
                    </div>
                    <Button texto={"Ingresar"} fill={true} tipo={1} click={()=>validLoginValuesAndGo()}></Button>
                </div>
                <div className='col-m12 center'>
                    <span className='caption'>1INF49 - Ingeniería de Requisitos</span>
                </div>
            </div>
        </div>
    )
}

export default Login;