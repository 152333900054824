// src/modules/ModuleARouter.js
import React from 'react';
import { Route, Routes  } from 'react-router-dom';
import CircleVibeProd from './CircleVibeProd';
import GaleriaPage from './GaleriaPage';
import ArticuloPage from './ArticuloPage';
import CasosPage from './CasosPage';
import ProyectosPage from './ProyectosPage';

const CircleVibeRouter = () => {
  return (
    <Routes>
      <Route path="/?" element={<CircleVibeProd />} />
      <Route path="galeria/:paramName" element={<GaleriaPage />} />
      <Route path="casoestudio/:paramName" element={<CasosPage />} />
      <Route path="articulo/:paramName" element={<ArticuloPage />} />
      <Route path="proyecto/:paramName" element={<ProyectosPage />} />
    </Routes>
  );
};

export default CircleVibeRouter;
