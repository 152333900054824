import React from 'react';
import './Breadcrumb.css';
import RightChevronIcon from '../../util/constants/icons/RightChevronIcon';

const Breadcrumb = (props)=>{

    const {step, textList} = props;

    const changeText=(value)=>{
        return value.substring(0, 10) + "..."
    }

    return (
        <div className='row breadcrumbContainer'>
            {textList.map((text,index)=>(
                <div key={index} className={`row center ${text===step? 'breadcrumbActive' : ''}`}>
                    {index!==0 && 
                        <RightChevronIcon size={"s"} ></RightChevronIcon>
                    }
                    <span className={`caption  `}>{text.length>12?changeText(text):text}</span>
                </div>
            ))}
        </div>
    )
}

export default Breadcrumb;