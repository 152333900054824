import './../Icon.css';
const ExperienceIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path fillRule="evenodd" clipRule="evenodd" d="M6 2.5C5.17157 2.5 4.5 3.17157 4.5 4V5H3.5C2.67157 5 2 5.67157 2 6.5V12C2 12.8284 2.67157 13.5 3.5 13.5H12.5C13.3284 13.5 14 12.8284 14 12V6.5C14 5.67157 13.3284 5 12.5 5H11.5V4C11.5 3.17157 10.8284 2.5 10 2.5H6ZM10.5 5V4C10.5 3.72386 10.2761 3.5 10 3.5H6C5.72386 3.5 5.5 3.72386 5.5 4V5H10.5Z"/>
        </svg>
    )
}

export default ExperienceIcon;



