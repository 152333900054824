import './../Icon.css';
const ProjectIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path d="M7 30.7167C7 29.0599 8.34315 27.7167 10 27.7167H13C14.6569 27.7167 16 29.0599 16 30.7167V39.7167C16 41.3736 14.6569 42.7167 13 42.7167H10C8.34315 42.7167 7 41.3736 7 39.7167V30.7167Z" />
            <path d="M19 24.7167C19 23.0599 20.3431 21.7167 22 21.7167H25C26.6569 21.7167 28 23.0599 28 24.7167V39.7167C28 41.3736 26.6569 42.7167 25 42.7167H22C20.3431 42.7167 19 41.3736 19 39.7167V24.7167Z"/>
            <path d="M31 15.7167C31 14.0599 32.3431 12.7167 34 12.7167H37C38.6569 12.7167 40 14.0599 40 15.7167V39.7167C40 41.3736 38.6569 42.7167 37 42.7167H34C32.3431 42.7167 31 41.3736 31 39.7167V15.7167Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.2963 8.70941C28.8526 8.87304 29.171 9.45668 29.0073 10.013L26.3409 19.079C26.1772 19.6354 25.5936 19.9537 25.0373 19.7901C24.4809 19.6265 24.1626 19.0428 24.3262 18.4865L26.2774 11.8524L12.0028 19.6385C11.4937 19.9162 10.8559 19.7286 10.5782 19.2195C10.3005 18.7104 10.4881 18.0726 10.9972 17.795L25.2718 10.0088L18.6377 8.05761C18.0814 7.89398 17.763 7.31033 17.9267 6.754C18.0903 6.19766 18.6739 5.87931 19.2303 6.04294L28.2963 8.70941Z" />
        </svg>
    )
}

export default ProjectIcon;



