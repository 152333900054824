import React, { useRef, useEffect, useState } from 'react';

const SlideInElementBottom = (props) => {

  const { center, children } = props;

  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(element);
        }
      });
    }, {
      threshold: 0.1, 
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);

  return (
    <div
      ref={elementRef}
      className={`slide-in-element-bottom ${isVisible ? 'show' : ''} ${center?"center": ""}`}
    >
      {children}
    </div>
  );
};

export default SlideInElementBottom;
