import React, { useEffect, useState } from 'react';
import Navigation from '../Navigation';
import Footer from '../Footer';
import './Profile.css';
import Input from './../../general-components/Input/Input';
import Button from './../../general-components/Button/Button';

const Profile = ()=>{

    const [personaNombre, setPersonaNombre] = useState("");
    const [personaCarrera, setPersonaCarrera] = useState("");
    const [personaTipoPlan, setPersonaTipoPlan] = useState("");
    const [personaPortaflio, setPersonaPortaflio] = useState("");
    const [personaCorreo, setPersonaCorreo] = useState("");

    const [disableCuenta, setDisableCuenta] = useState(true);
    const [personaNombreAux, setPersonaNombreAux] = useState("");
    const [personaCarreraAux, setPersonaCarreraAux] = useState("");
    const [personaCorreoAux, setPersonaCorreoAux] = useState("");

    const [cambiarContrasenia, setCambiarContrasenia] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
    const [currentPasswordAux, setCurrentPasswordAux] = useState("");
    const [newPasswordAux, setNewPasswordAux] = useState("");
    const [newPasswordConfirmationAux, setNewPasswordConfirmationAux] = useState("");

    useEffect(() => {
        setPersonaNombre(window.sessionStorage.getItem("personaNombre"));
        setPersonaCarrera(window.sessionStorage.getItem("personaCarrera"));
        setPersonaTipoPlan(window.sessionStorage.getItem("personaTipoPlan"));
        setPersonaPortaflio(window.sessionStorage.getItem("personaPortaflio"));
        setPersonaCorreo(window.sessionStorage.getItem("personaCorreo"));
        setPersonaNombreAux(personaNombre);
        setPersonaCarreraAux(personaCarrera);
        setPersonaCorreoAux(personaCorreo);
    }, []);

    const handleGuardarCambiosCuenta = () => {
        setPersonaNombre((prevPersonaNombre) => personaNombreAux);
        setPersonaCarrera((prevPersonaCarrera) => personaCarreraAux);
        setPersonaCorreo((prevPersonaCorreo) => personaCorreoAux);
        setDisableCuenta(true);
        // TODO: You can send the updated data to the backend here.
    }

    const handleDescartarCambiosCuenta=()=>{
        setPersonaNombreAux(personaNombre);
        setPersonaCarreraAux(personaCarrera);
        setPersonaCorreoAux(personaCorreo);
        setDisableCuenta(true);
    }

    const handlePassword=(key,value)=>{
        let newValue = "*".repeat(value.length);
        switch(key){
            case 1:
                setCurrentPassword(value);
                setCurrentPasswordAux(newValue);
                break;
            case 2:
                setNewPassword(value);
                setNewPasswordAux(newValue);
                break;
            case 3:
                setNewPasswordConfirmation(value);
                setNewPasswordConfirmationAux(newValue);
                break;
            default : break;
        }
    }

    const handleActualizarContrasenia=()=>{
        // TODO enviar a BD
        // TODO validar que sean iguales
        emptyPasswords();
        setCambiarContrasenia(false);

    }

    const handleDescartarContrasenia=()=>{
        emptyPasswords();
        setCambiarContrasenia(false);
    }

    const emptyPasswords=()=>{
        setCurrentPasswordAux("");
        setNewPasswordAux("");
        setNewPasswordConfirmationAux("");
    }

    const handleEliminarCuenta=()=>{
        // TODO handleEliminarCuenta
    }

    return(
        <div>
            <Navigation/>
            <div className='profileContainer'>
                <div className='profileTitle'>
                    <h4 className='heading_4'>Mi perfil</h4>
                    <div className='profileTitleDetail'>
                        <div className='heading2'>{personaNombre}</div>
                        <p className='subtitle_2'>{personaCarrera} • {personaTipoPlan===1?"Basic Plan":"Premium Plan"}</p>
                    </div>
                </div>
                <div className='profilePortafolio'>
                    <div className='heading5'>Portafolio(s):</div>
                    <div className='cardProfilePortafolio'>
                        <p className='subtitle_1'>{personaNombre}, aun no has creado tu portafolio</p>
                        <div className='col-m4 gap8'>
                            <Button texto={"Crear Portafolio"} tipo={1}></Button>
                            <Button texto={"Ver Plantilla"} tipo={3}></Button>
                        </div>
                    </div>
                </div>
                <div className='profilePortafolio'>
                    <div className='heading5'>Cuenta:</div>
                    <div className='cardProfileCuenta'>
                        {!cambiarContrasenia?(
                            <div className='cardProfileCuentaCampos'>
                                <Input name={"nombre"}
                                        label={"Nombre"}
                                        value={disableCuenta?personaNombre:personaNombreAux}
                                        disabled={disableCuenta}
                                        changer={(event)=>setPersonaNombreAux(event.target.value)}
                                />
                                <Input name={"carrera"}
                                        label={"Profesión o campo de estudio"}
                                        value={disableCuenta?personaCarrera:personaCarreraAux}
                                        disabled={disableCuenta}
                                        changer={(event)=>setPersonaCarreraAux(event.target.value)}
                                />
                                <Input name={"correo"}
                                        label={"Correo Electrónico"}
                                        value={disableCuenta?personaCorreo:personaCorreoAux}
                                        disabled={disableCuenta}
                                        changer={(event)=>setPersonaCorreoAux(event.target.value)}
                                />
                            </div>
                        ):(
                            <div className='cardProfileCuentaCampos'>
                                <Input name={"currentPassword"}
                                        label={"Contraseña Actual"}
                                        value={currentPasswordAux}
                                        changer={(event)=>handlePassword(1,event.target.value)}
                                />
                                <Input name={"newPassword"}
                                        label={"Nueva Contraseña"}
                                        value={newPasswordAux}
                                        changer={(event)=>handlePassword(2,event.target.value)}
                                />
                                <Input name={"newPasswordConfirmation"}
                                        label={"Confirma la nueva contraseña"}
                                        value={newPasswordConfirmationAux}
                                        changer={(event)=>handlePassword(3,event.target.value)}
                                />
                            </div>
                        )}
                        {cambiarContrasenia?(
                            <div className='cardProfileCuentaOpciones'>
                                <Button texto={"Descartar"} tipo={3} click={handleDescartarContrasenia}></Button>
                                <Button texto={"Actualizar contraseña"} tipo={1} click={handleActualizarContrasenia}></Button>
                            </div>
                        ):(
                            disableCuenta?(
                                <div className='cardProfileCuentaOpciones'>
                                    <Button texto={"Eliminar Cuenta"} tipo={2} click={handleEliminarCuenta}></Button>
                                    <Button texto={"Actualizar Datos"} tipo={3} click={()=>setDisableCuenta(!disableCuenta)}></Button>
                                </div>
                            ):(
                                <div className='cardProfileCuentaOpciones'>
                                    <Button texto={"Actualizar Contraseña"} tipo={2} click={() => setCambiarContrasenia(!cambiarContrasenia)}></Button>
                                    <Button texto={"Descartar Cambios"} tipo={3} click={handleDescartarCambiosCuenta}></Button>
                                    <Button texto={"Guardar Cambios"} tipo={1} click={handleGuardarCambiosCuenta}></Button>
                                </div>
                            )
                        )
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Profile;