import './../Icon.css';
const TestimonyIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path fillRule="evenodd" clipRule="evenodd" d="M9 8H39C40.6569 8 42 9.34315 42 11V29C42 30.6569 40.6569 32 39 32H25.2426C24.447 32 23.6839 32.3161 23.1213 32.8787L15.6213 40.3787C14.8385 41.1615 13.5 40.6071 13.5 39.5V33.5C13.5 32.6716 12.8284 32 12 32H9C7.34315 32 6 30.6569 6 29V11C6 9.34315 7.34315 8 9 8ZM16.5 15.5C15.6716 15.5 15 16.1716 15 17C15 17.8284 15.6716 18.5 16.5 18.5H31.5C32.3284 18.5 33 17.8284 33 17C33 16.1716 32.3284 15.5 31.5 15.5H16.5ZM16.5 21.5C15.6716 21.5 15 22.1716 15 23C15 23.8284 15.6716 24.5 16.5 24.5H31.5C32.3284 24.5 33 23.8284 33 23C33 22.1716 32.3284 21.5 31.5 21.5H16.5Z"/>
        </svg>
    )
}

export default TestimonyIcon;



