import React from 'react';
import './Landing.css';
import RightArrowIcon from '../../util/constants/icons/RightArrowIcon';
import Button from '../../general-components/Button/Button';
import { useNavigate } from 'react-router-dom';
import landing1 from './../../assets/img/landing1.png';
import landing2 from './../../assets/img/landing2.png';
import Pricing from '../Pricing';

const Landing = ()=>{
    const navigate = useNavigate ();
    
    
    
    const goTo = () => {

    navigate('/portafolios');
    
    /*
    

    let user = window.sessionStorage.getItem("user");

    if(user ===null || user ===''){
      openLogin();
    }else{
      navigate('/portafolios');
    }
    */
  };

    return (
        <div className='landingContainer center'>
            <div className='landingContentContainer'>
                <div className='col' style={{gap:"120px", position:"relative"}}>
                    <div className='landingElipse'></div>
                    <div className='col gap32 center'>
                        <h1 className='heading_1 centerText'>Crea Tu Portafolio Digital De Manera Rápida y Sencilla</h1>
                        <p className='body_xl centerText' style={{width:"783px"}}>Con Profolio, crea un portafolio impresionante y proyecta tu marca personal de forma fácil. Plantillas, personalización y ¡listo! </p>
                        <div className='row center' style={{zIndex:"1"}}>
                            <Button texto={"Empezar Demo"} tipo={1} size={"s"} click={goTo}></Button>
                        </div>
                    </div>
                    <div className='landingDevices'>
                        <div className={`landingImage1`} style={{backgroundImage:  `url(${landing1})`}}></div>
                        <div className={`landingImage2`} style={{backgroundImage:  `url(${landing2})`}}></div>
                    </div>
                </div>
            </div>
            <div className='landingContentContainer'>
                <h2 className='heading_2 centerText'>¿Cómo creo mi portafolio?</h2>
                <div className='col gap64'>
                    <div className='col gap16 center'>
                        <div className='landingStep'>
                            <p className='body_xl centerText'>1</p>
                        </div>
                        <div className='landingLine2'></div>
                        <h3 className='heading_3 centerText'>Selecciona una plantilla</h3>
                        <p className='body_l centerText' style={{width:"786px"}}>Elige entre nuestras increíbles plantillas diseñadas profesionalmente. Desde elegantes a modernas, encuentra la que se adapte a tu estilo.</p>
                    </div>
                    
                </div>
                <div className='landingLine1'></div>
                <div className='col gap64'>
                    <div className='col gap16 center'>
                        <div className='landingStep'>
                            <p className='body_xl centerText'>2</p>
                        </div>
                        <div className='landingLine2'></div>
                        <h3 className='heading_3 centerText'>Personaliza tu portafolio</h3>
                        <p className='body_l centerText' style={{width:"786px"}}>Selecciona plantillas personalizables, añade tu toque único y comparte tu talento de manera profesional en línea. </p>
                    </div>

                </div>
                <div className='landingLine1'></div>
                <div className='col gap16 center'>
                    <div className='landingStep'>
                        <p className='body_xl centerText'>3</p>
                    </div>
                    <div className='landingLine2'></div>
                    <h3 className='heading_3 centerText'>Publica y Comparte</h3>
                    <p className='body_l centerText' style={{width:"786px"}}>¡Listo para el mundo! Publica tu portafolio con un solo clic y comparte tu talento con compañeros, clientes, cazatalentos y empleadores. </p>
                    <div className='row center'>
                        <Button texto={"Empezar Demo"} tipo={1} size={"s"} click={goTo}></Button>
                    </div>
                </div>
            </div>
            <div className='landingContentContainer'>
                <Pricing></Pricing>
            </div>
        </div>
    )
}

export default Landing;