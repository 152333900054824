import React from 'react';
import './CardFormAgregar.css';
import CheckBox from '../../util/CheckBox';

const CardFormAgregar = (props) =>{
    const { icon: Icon, titulo, info, onAgregarClick, value } = props; 

    const handleClickCheckBox = () => {
        onAgregarClick();
    };

    return (
        <div className={`${!value ? 'container-cardFormAgregar' : 'container-cardFormAgregarSelected'} clickeable`} onClick={handleClickCheckBox}>
            <Icon></Icon>
            <h6 className='heading_6'>{titulo}</h6>
            <div className='col center' style={{width:"200px"}}>
                <label className='inputLabel'>{info}</label>
            </div>
        </div>
    );
};

export default CardFormAgregar;