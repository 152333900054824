import React from 'react';
import './Popup.css';
import Button from '../Button/Button';

const Popup=(props)=>{

    const {title, text, close, btn1Config, btn2Config, children} = props;

    return(
        <div className="popupContainer">
          <div className="popupContent">
            <div className='col-m12 gap16'>
                <h5 className='heading_5 center'>{title}</h5>
                {text && <p className='body_s'>{text}</p>}
                {React.Children.map(children, (child) =>
                    React.cloneElement(child)
                )}
                <div className='row-m12 gap8 right'>
                    {(btn1Config.text !== undefined && btn1Config.text !== null) && 
                      <Button texto={btn1Config.text} tipo={2} error={btn1Config.error} click={()=>close(false)}></Button>
                    }
                    {(btn2Config.text !== undefined && btn2Config.text !== null) && 
                      <Button texto={btn2Config.text} tipo={1} error={btn2Config.error} click={()=>close(true)}></Button>
                    }
                </div>
            </div>
          </div>
        </div>
    )
}

export default Popup;