import './App.css';
import HomeRouter from './components/HomeRouter';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Formulario from './components/Formulario/Formulario';
import Portafolios from './components/Portafolios';
import CircleVibeRouter from './components/PoolFormularios/CircleVibe/CircleVibeRouter';
import { InputValuesProvider } from './util/InputValuesContext';
import Checkout from './components/Payment/Checkout';
import Profile from './components/Profile/Profile';
import PortafoliosRouter from './components/PortafoliosRouter';
import Login from './components/inf/Login/Login';

function App() {

  return (
    <BrowserRouter>
    <InputValuesProvider>
      <Routes>
        <Route path="/" element={<HomeRouter />} />
        <Route path="/login" element={<Login />} />
        <Route path="/formulario" element={<Formulario />} />
        <Route path="/portafolios/*" element={<PortafoliosRouter />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/show/*" element={<CircleVibeRouter />} />
        <Route path="/circlevibe/*" element={<CircleVibeRouter />} />
      </Routes>
      </InputValuesProvider>
    </BrowserRouter>
  );
}

export default App;
