import React, { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import { Link } from 'react-scroll';
import { Link as Linker} from 'react-router-dom';
import logo from '../assets/icons/profolio-logotipo.svg';
import './../css/Navigation.css';
import User from '@material-ui/icons/Person';
import Button from '../general-components/Button/Button';
import RightArrowIcon from '../util/constants/icons/RightArrowIcon';

const Navigation = (props) => {
  const navigate = useNavigate ();

  /*
  const {loginFunction} = props;

  const [user, setUser] = useState( window.sessionStorage.getItem("user"));

  useEffect(() => {
    let userAux = window.sessionStorage.getItem("user");
    setUser(userAux);
  }, [window.sessionStorage.getItem("user")]);

  */
 const goTo = () => {

    navigate('/portafolios');
    
    /*
    navigate('/portafolios');

    let user = window.sessionStorage.getItem("user");

    if(user ===null || user ===''){
      openLogin();
    }else{
      navigate('/portafolios');
    }
    */
  };

  return (
      <div className='top-bar'>
        <Linker to="/">
          <img src={logo} alt='' className='logo'></img>
        </Linker>
        <Button texto={"Empezar Demo"} tipo={1} size={"s"} click={goTo}></Button>
        {/*
          div className='col'>
          <div className='row center'>
            {user ==="" || user===null? (
              <div className='row gap8'>
                <Button texto={"Registrarme"} tipo={1} click={()=>loginFunction(1)}></Button>
                <Button texto={"Iniciar sesión"} tipo={2} click={()=>loginFunction(0)}></Button>
              </div>
            ):(
              <div  className='user-bg clickeable' onClick={()=>navigate('/profile')}>
                <User className='user'></User>
              </div>
            )}            
          </div>
        </div>
        */}
      </div>
  );
};

export default Navigation;
