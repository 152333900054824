import React from 'react';

const CheckBox = (props) => {
    const { checked,text,onClickP } = props; 

    const handleClick = () => {
        if(onClickP) onClickP();
    };
    return (
        <div className='col center clickeable' onClick={handleClick}>
            <div className='row gap4 center'>
                {!checked ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15"  className="custom-fill2">
                        <path d="M1.75 14.25C1.3375 14.25 0.984375 14.1031 0.690625 13.8094C0.396875 13.5156 0.25 13.1625 0.25 12.75V2.25C0.25 1.8375 0.396875 1.48438 0.690625 1.19063C0.984375 0.896875 1.3375 0.75 1.75 0.75H12.25C12.6625 0.75 13.0156 0.896875 13.3094 1.19063C13.6031 1.48438 13.75 1.8375 13.75 2.25V12.75C13.75 13.1625 13.6031 13.5156 13.3094 13.8094C13.0156 14.1031 12.6625 14.25 12.25 14.25H1.75ZM1.75 12.75H12.25V2.25H1.75V12.75Z" fill="#BCBCBC"/>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" className="custom-fill2">
                        <path d="M5.95 10.65L11.2375 5.3625L10.1875 4.3125L5.95 8.55L3.8125 6.4125L2.7625 7.4625L5.95 10.65ZM1.75 14.25C1.3375 14.25 0.984375 14.1031 0.690625 13.8094C0.396875 13.5156 0.25 13.1625 0.25 12.75V2.25C0.25 1.8375 0.396875 1.48438 0.690625 1.19063C0.984375 0.896875 1.3375 0.75 1.75 0.75H12.25C12.6625 0.75 13.0156 0.896875 13.3094 1.19063C13.6031 1.48438 13.75 1.8375 13.75 2.25V12.75C13.75 13.1625 13.6031 13.5156 13.3094 13.8094C13.0156 14.1031 12.6625 14.25 12.25 14.25H1.75Z" fill="white"/>
                    </svg>
                )}
                <div className='radioButtonText'>{text}</div>
            </div>
        </div>
    )
}

export default CheckBox;