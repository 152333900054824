const ProfolioIcon=(props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="90" height="24" viewBox="0 0 90 24" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <g clipPath="url(#clip0_3186_5282)">
                <path d="M7.03125 16.2188H0V23.25H7.03125V16.2188Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M7.96875 15.2812H12.6562C13.9507 15.2812 15 14.2804 15 13.0457V2.98558C15 1.7509 13.9507 0.75 12.6562 0.75H7.96875V15.2812Z" />
                <path d="M7.03125 0.75H0V15.2812H7.03125V0.75Z" />
                <path d="M17.3438 23.25V9.87298H20.8531V12.1623H20.9891C21.227 11.3479 21.6264 10.7329 22.1872 10.3172C22.748 9.89576 23.3938 9.68506 24.1246 9.68506C24.3059 9.68506 24.5013 9.69645 24.7109 9.71923C24.9205 9.74201 25.1046 9.77332 25.2632 9.81321V13.0421C25.0933 12.9909 24.8582 12.9453 24.558 12.9055C24.2577 12.8656 23.983 12.8457 23.7337 12.8457C23.2012 12.8457 22.7254 12.9624 22.3062 13.1959C21.8926 13.4237 21.5641 13.7426 21.3205 14.1526C21.0825 14.5626 20.9636 15.0353 20.9636 15.5706V23.25H17.3438Z"/>
                <path d="M32.4243 23.25C31.1044 23.25 29.963 22.9681 28.9999 22.4043C28.0426 21.8349 27.3033 21.0433 26.7821 20.0296C26.261 19.0103 26.0004 17.8286 26.0004 16.4846C26.0004 15.1293 26.261 13.9448 26.7821 12.9311C27.3033 11.9117 28.0426 11.1202 28.9999 10.5564C29.963 9.98691 31.1044 9.70215 32.4243 9.70215C33.7443 9.70215 34.8829 9.98691 35.8403 10.5564C36.8033 11.1202 37.5454 11.9117 38.0666 12.9311C38.5877 13.9448 38.8483 15.1293 38.8483 16.4846C38.8483 17.8286 38.5877 19.0103 38.0666 20.0296C37.5454 21.0433 36.8033 21.8349 35.8403 22.4043C34.8829 22.9681 33.7443 23.25 32.4243 23.25ZM32.4413 20.4311C33.0418 20.4311 33.5431 20.2603 33.9454 19.9186C34.3476 19.5712 34.6507 19.0985 34.8546 18.5006C35.0642 17.9026 35.169 17.2221 35.169 16.459C35.169 15.6959 35.0642 15.0154 34.8546 14.4174C34.6507 13.8195 34.3476 13.3468 33.9454 12.9994C33.5431 12.652 33.0418 12.4784 32.4413 12.4784C31.8352 12.4784 31.3253 12.652 30.9118 12.9994C30.504 13.3468 30.1952 13.8195 29.9856 14.4174C29.7817 15.0154 29.6797 15.6959 29.6797 16.459C29.6797 17.2221 29.7817 17.9026 29.9856 18.5006C30.1952 19.0985 30.504 19.5712 30.9118 19.9186C31.3253 20.2603 31.8352 20.4311 32.4413 20.4311Z" />
                <path d="M48.2208 9.87306V12.6066H40.1654V9.87306H48.2208ZM42.0093 23.2501V8.92487C42.0093 7.97387 42.1934 7.18515 42.5617 6.55871C42.9355 5.93228 43.4453 5.46249 44.0911 5.14926C44.7369 4.83605 45.4705 4.67944 46.292 4.67944C46.8471 4.67944 47.3541 4.72215 47.813 4.80758C48.2775 4.893 48.623 4.96988 48.8498 5.03822L48.2039 7.7717C48.0623 7.72614 47.8866 7.68343 47.677 7.64354C47.4731 7.6037 47.2636 7.58378 47.0484 7.58378C46.5157 7.58378 46.1447 7.70907 45.9351 7.95962C45.7255 8.20449 45.6207 8.54903 45.6207 8.99321V23.2501H42.0093Z" />
                <path d="M55.8619 23.25C54.5423 23.25 53.4005 22.9681 52.4376 22.4043C51.4805 21.8349 50.7408 21.0433 50.22 20.0296C49.6987 19.0103 49.4381 17.8286 49.4381 16.4846C49.4381 15.1293 49.6987 13.9448 50.22 12.9311C50.7408 11.9117 51.4805 11.1202 52.4376 10.5564C53.4005 9.98691 54.5423 9.70215 55.8619 9.70215C57.1819 9.70215 58.3205 9.98691 59.2781 10.5564C60.2409 11.1202 60.983 11.9117 61.5042 12.9311C62.0255 13.9448 62.2861 15.1293 62.2861 16.4846C62.2861 17.8286 62.0255 19.0103 61.5042 20.0296C60.983 21.0433 60.2409 21.8349 59.2781 22.4043C58.3205 22.9681 57.1819 23.25 55.8619 23.25ZM55.8792 20.4311C56.4797 20.4311 56.9808 20.2603 57.383 19.9186C57.7851 19.5712 58.0884 19.0985 58.2923 18.5006C58.5019 17.9026 58.6069 17.2221 58.6069 16.459C58.6069 15.6959 58.5019 15.0154 58.2923 14.4174C58.0884 13.8195 57.7851 13.3468 57.383 12.9994C56.9808 12.652 56.4797 12.4784 55.8792 12.4784C55.2731 12.4784 54.7631 12.652 54.3497 12.9994C53.9419 13.3468 53.633 13.8195 53.4234 14.4174C53.2195 15.0154 53.1173 15.6959 53.1173 16.459C53.1173 17.2221 53.2195 17.9026 53.4234 18.5006C53.633 19.0985 53.9419 19.5712 54.3497 19.9186C54.7631 20.2603 55.2731 20.4311 55.8792 20.4311Z"/>
                <path d="M68.2598 5.49939V23.25H64.6397V5.49939H68.2598Z" />
                <path d="M71.1595 23.25V9.873H74.7792V23.25H71.1595ZM72.9778 8.18166C72.4397 8.18166 71.978 8.00227 71.5927 7.64348C71.213 7.27903 71.0236 6.84338 71.0236 6.33656C71.0236 5.83542 71.213 5.40547 71.5927 5.0467C71.978 4.68223 72.4397 4.5 72.9778 4.5C73.5159 4.5 73.9748 4.68223 74.3545 5.0467C74.7394 5.40547 74.932 5.83542 74.932 6.33656C74.932 6.84338 74.7394 7.27903 74.3545 7.64348C73.9748 8.00227 73.5159 8.18166 72.9778 8.18166Z" />
                <path d="M83.5763 23.25C82.2563 23.25 81.1149 22.9681 80.1516 22.4043C79.1944 21.8349 78.4552 21.0433 77.9339 20.0296C77.4127 19.0103 77.152 17.8286 77.152 16.4846C77.152 15.1293 77.4127 13.9448 77.9339 12.9311C78.4552 11.9117 79.1944 11.1202 80.1516 10.5564C81.1149 9.98691 82.2563 9.70215 83.5763 9.70215C84.8958 9.70215 86.0344 9.98691 86.992 10.5564C87.9548 11.1202 88.6969 11.9117 89.2181 12.9311C89.7394 13.9448 90 15.1293 90 16.4846C90 17.8286 89.7394 19.0103 89.2181 20.0296C88.6969 21.0433 87.9548 21.8349 86.992 22.4043C86.0344 22.9681 84.8958 23.25 83.5763 23.25ZM83.5931 20.4311C84.1936 20.4311 84.6947 20.2603 85.0969 19.9186C85.4991 19.5712 85.8023 19.0985 86.0063 18.5006C86.2158 17.9026 86.3208 17.2221 86.3208 16.459C86.3208 15.6959 86.2158 15.0154 86.0063 14.4174C85.8023 13.8195 85.4991 13.3468 85.0969 12.9994C84.6947 12.652 84.1936 12.4784 83.5931 12.4784C82.987 12.4784 82.477 12.652 82.0636 12.9994C81.6558 13.3468 81.3469 13.8195 81.1374 14.4174C80.9334 15.0154 80.8313 15.6959 80.8313 16.459C80.8313 17.2221 80.9334 17.9026 81.1374 18.5006C81.3469 19.0985 81.6558 19.5712 82.0636 19.9186C82.477 20.2603 82.987 20.4311 83.5931 20.4311Z" />
            </g>
            <defs>
                <clipPath id="clip0_3186_5282">
                <rect width="90" height="22.5" transform="translate(0 0.75)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default ProfolioIcon;