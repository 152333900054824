import React, { useEffect, useRef, useState } from "react";
import CardFormAgregar from "../CardFormAgregar/CardFormAgregar";
import RightArrowIcon from "../../util/constants/icons/RightArrowIcon";
import Button from "../../general-components/Button/Button";
import './SectionPopUp.css';
import ClickOutsideClose from "../../util/functions/General/ClickOutsideClose";
import BannerIcon from './../../util/constants/icons/SectionIcons/BannerIcon';
import AboutMeIcon from './../../util/constants/icons/SectionIcons/AboutMeIcon';
import ServicesIcon from './../../util/constants/icons/SectionIcons/ServicesIcon';
import EducationIcon from './../../util/constants/icons/SectionIcons/EducationIcon';
import ExperienceIcon from './../../util/constants/icons/SectionIcons/ExperienceIcon';
import SkillsIcon from './../../util/constants/icons/SectionIcons/SkillsIcon';
import ContactIcon from './../../util/constants/icons/SectionIcons/ContactIcon';
import TestimonyIcon from './../../util/constants/icons/SectionIcons/TestimonyIcon';
import GaleryIcon from './../../util/constants/icons/SectionIcons/GaleryIcon';
import ProjectIcon from './../../util/constants/icons/SectionIcons/ProjectIcon';
import CaseIcon from './../../util/constants/icons/SectionIcons/CaseIcon';
import ArticleIcon from './../../util/constants/icons/SectionIcons/ArticleIcon';
import ProfolioIcon from './../../util/constants/icons/ProfolioIcon';

const SectionPopUp = (props)=>{
    
    const {onClose,iscardState} = props;
    const sectionRef = useRef(null);

    ClickOutsideClose(sectionRef,()=>onClose(iscardState));
    
    const [cardStateCopy, setCardStateCopy] = useState([]);
    const [step, setStep] = useState(1);

    useEffect(() => {
        setCardStateCopy(iscardState);
    }, []);

    const handleSubmit = (isSubmit) => {
        if(isSubmit) onClose(cardStateCopy);
        else onClose(iscardState);
    };

    const handleValueChange = (value)=>{
        setCardStateCopy((prevCardState) => ({
            ...prevCardState,
            [value]: !prevCardState[value],
        }));
    }

    return (
        <div className="sectionPopupBackground center">
            <div className="sectionPopupContainer" ref={sectionRef}>
                <div className={`col-m12 gap24`}>
                    <div className="center profolioIcon">
                        <ProfolioIcon></ProfolioIcon>
                    </div>
                    <div className={`row-m12 gap24 sectionPopupStepContainer sectionPopupState${step}`}>
                        <div className="col-m12 gap16 sectionPopupStep1 non-shrinkable">
                            <h5 className='heading_5 center'>¿Qué deseas incluir dentro de tu portafolio?</h5>
                            <div className='col-m12 gap32 center '>
                                <div className='row-m12 center gap16 wrap-row'>
                                    {cardStateCopy['Banner']===false &&
                                        <CardFormAgregar icon={BannerIcon} titulo={"Portada"} info={"Muestra tu marca personal a primera vista"}
                                            onAgregarClick={() => handleValueChange('Banner')} value={cardStateCopy['Banner']}></CardFormAgregar>
                                    }
                                    <CardFormAgregar icon={AboutMeIcon} titulo={"Sobre mí"} info={"Comparte tu historia y trayectoria profesional."}
                                        onAgregarClick={() => handleValueChange('SobreMi')} value={cardStateCopy['SobreMi']}></CardFormAgregar>
                                    <CardFormAgregar icon={ServicesIcon} titulo={"Servicios"} info={"Lista tus servicios y propuesta de valor."}
                                        onAgregarClick={() => handleValueChange('Servicios')} value={cardStateCopy['Servicios']}></CardFormAgregar>
                                    <CardFormAgregar icon={ExperienceIcon} titulo={"Experiencia"} info={"Muestra tu historial laboral y logros clave."}
                                        onAgregarClick={() => handleValueChange('Experiencia')} value={cardStateCopy['Experiencia']}></CardFormAgregar>
                                    <CardFormAgregar icon={EducationIcon} titulo={"Educación"} info={"Presenta tus logros académicos y formación."}
                                        onAgregarClick={() => handleValueChange('Educacion')} value={cardStateCopy['Educacion']}></CardFormAgregar>
                                    <CardFormAgregar icon={SkillsIcon} titulo={"Habilidades"} info={"Destaca tus competencias y herramientas especiales"}
                                        onAgregarClick={() => handleValueChange('Habilidades')} value={cardStateCopy['Habilidades']}></CardFormAgregar>
                                    {cardStateCopy['Contacto']===false &&
                                        <CardFormAgregar icon={ContactIcon} titulo={"Contacto"} info={"Facilita tus datos de contactos y redes sociales"}
                                            onAgregarClick={() => handleValueChange('Contacto')} value={cardStateCopy['Contacto']}></CardFormAgregar>
                                    }
                                    <CardFormAgregar icon={TestimonyIcon} titulo={"Testimonios"} info={"Incluye opiniones de clientes o compañeros."}
                                        onAgregarClick={() => handleValueChange('Testimonios')} value={cardStateCopy['Testimonios']}></CardFormAgregar>
                                </div>
                            </div>
                        </div>
                        <div className="col-m12 gap16 sectionPopupStep2 center non-shrinkable">
                            <h5 className='heading_5 center'>¿Qué deseas incluir dentro de tu portafolio?</h5>
                            <div className='col-m10 gap32 center '>
                                <div className='row-m10 center gap16  wrap-row'>
                                    <CardFormAgregar icon={GaleryIcon} titulo={"Galerías"} info={"Exhibe una colección de tus mejores trabajos visuales."}
                                        onAgregarClick={() => handleValueChange('Galeria')} value={cardStateCopy['Galeria']}></CardFormAgregar>
                                    <CardFormAgregar icon={ProjectIcon} titulo={"Proyectos"} info={"Presenta tus proyectos más relevantes"}
                                        onAgregarClick={() => handleValueChange('Proyectos')} value={cardStateCopy['Proyectos']}></CardFormAgregar>
                                    <CardFormAgregar icon={CaseIcon} titulo={"Casos de estudio"} info={"Muestra el análisis de tus mejores trabajos.."}
                                        onAgregarClick={() => handleValueChange('Casos')} value={cardStateCopy['Casos']}></CardFormAgregar>
                                    <CardFormAgregar icon={ArticleIcon} titulo={"Artículos"} info={"Comparte publicaciones o escritos de tu campo."}
                                        onAgregarClick={() => handleValueChange('Articulo')} value={cardStateCopy['Articulo']}></CardFormAgregar>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row-m12 right gap8'>
                        {step===1?(
                            <Button texto={"Cerrar"} tipo={2} click={()=>{handleSubmit(false)}}></Button>
                        ):(
                            <Button texto={"Regresar"} tipo={2} click={()=>setStep(1)}></Button>
                        )}
                        {step===1?(
                            <Button texto={"Siguiente"} tipo={1} rightIcon={RightArrowIcon} click={()=>setStep(2)}></Button>
                        ):(
                            <Button texto={"Continuar"} tipo={1} rightIcon={RightArrowIcon} click={()=>{handleSubmit(true)}}></Button>
                        )}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionPopUp;