import React, { useContext, useEffect, useRef, useState} from 'react';
import { useNavigate, useLocation   } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Droppable from '../../util/Droppable';
import Draggable from '../../util/Draggable';
import Toastr from '../../util/Toastr';
import SectionPopUp from '../../special-components/SectionPopUp/SectionsPopUp';
import { InputValuesContext } from '../../util/InputValuesContext';
import Button from '../../general-components/Button/Button';
import GeneralForm from '../../general-components/Forms/GeneralForm';
import MvpForm from '../../general-components/Forms/MvpForm/MvpForm';
import Popup from '../../general-components/Popup/Popup';
import Loader from '../../general-components/Loader/Loader';
import Tab from '../../general-components/Tab/Tab';
import SectionCard from '../../general-components/SectionCard/SectionCard';
import BuilderTopBar from '../../general-components/TopBar/BuilderTopBar/BuilderTopBar';
import VisualConfiguration from '../../special-components/VisualConfiguration/VisualConfiguration/VisualConfiguration';
import PlusIcon from '../../util/constants/icons/PlusIcon';
import BannerIcon from '../../util/constants/icons/SectionIcons/BannerIcon';
import AboutMeIcon from '../../util/constants/icons/SectionIcons/AboutMeIcon';
import ServicesIcon from '../../util/constants/icons/SectionIcons/ServicesIcon';
import ExperienceIcon from '../../util/constants/icons/SectionIcons/ExperienceIcon';
import ContactIcon from '../../util/constants/icons/SectionIcons/ContactIcon';
import EducationIcon from '../../util/constants/icons/SectionIcons/EducationIcon';
import SkillsIcon from '../../util/constants/icons/SectionIcons/SkillsIcon';
import TestimonyIcon from '../../util/constants/icons/SectionIcons/TestimonyIcon';
import GaleryIcon from '../../util/constants/icons/SectionIcons/GaleryIcon';
import ProjectIcon from '../../util/constants/icons/SectionIcons/ProjectIcon';
import CaseIcon from '../../util/constants/icons/SectionIcons/CaseIcon';
import ArticleIcon from '../../util/constants/icons/SectionIcons/ArticleIcon';

const Formulario = () => {
    const { seccionesData, setGeneral } = useContext(InputValuesContext);
    
    const [showSections, setShowSections] = useState(true);
    const [showSectionDetail, setShowSectionDetail] = useState(false);

    const [items, setItems] = useState([]);
    const [itemsNames, setItemsNames] = useState([]);
    const [sectionDetail, setSectionDetail] = useState({ component: null });
    const [sectionDetailNombre, setSectionDetailNombre] = useState("");
    const [showEliminarPopup, setShowEliminarPopup] = useState(false);
    const [showChangeSectionPopup, setShowChangeSectionPopup] = useState(false);
    const [showToastr,setShowToastr] = useState(false);
    const [toastrText,setToastrText] = useState("");
    const childRef = useRef();
    const portfolioRef = useRef();
    const [menu,setMenu] = useState(0);
    const [deleteSectionCardValue, setDeleteSectionCardValue] = useState("");
    const [showSubGaleria, setShowSubGaleria] = useState(false);
    const [subGaleriaIndex,setSubGaleriaIndex] = useState(-1);
    const [showSubCaso, setShowSubCaso] = useState(false);
    const [subCasoIndex,setSubCasoIndex] = useState(-1);
    const [showSubProyecto, setShowSubProyecto] = useState(false);
    const [subProyectoIndex,setSubProyectoIndex] = useState(-1);
    const [showSubArticulo, setShowSubArticulo] = useState(false);
    const [subArticuloIndex,setSubArticuloIndex] = useState(-1);
    const [tabFirstValue,setTabFirstValue] = useState("Secciones");

    const [portafolioComponent, setPortafolioComponent] = useState({component : null});
    const [preview,setPreview] = useState("Computer");

    const [showForm, setShowForm] = useState(false);
    const [showThanksPopup, setShowThanksPopup] = useState(false);
    const [loginStage, setLoginStage] = useState(0);
    const [submenuFuture, setSubmenuFuture] = useState(0);
    const [showLoader, setShowLoader] = useState(false);

    const [showVisualConfigurationDetail, setShowVisualConfigurationDetail] = useState(false);

    const [tabItemSelected, setTabItemSelected] = useState("Secciones");

    const navigate = useNavigate ();
    const location = useLocation();

    const handleBackButton=()=>{
        setShowChangeSectionPopup(true);
        //setShowSectionDetail(false);
    }
    
    const handleClosePopup = (value) => {
        setShowSections(false);
        let newItems = [];
        let especiales = [false,false,false,false];
        for (const [key, v] of Object.entries(value)){
            setGeneral('inputs',key,v)
            if(v){
                // TODO refactorizar Sobre mi para que sea estandarizado
                if(key==='SobreMi') newItems.push("Sobre mi");
                else newItems.push(key);
                if(key=== 'Galeria') especiales[0]=true;
                if(key=== 'Casos')especiales[1]=true;
                if(key=== 'Proyectos')especiales[2]=true;
                if(key=== 'Articulo')especiales[3]=true;
            }
        }
        setItemsNames(newItems);
    };

    useEffect(() => {
    }, [itemsNames]);

    useEffect(() => {
    }, [sectionDetailNombre]);

    useEffect(() => {
        const getPortafolio = async () => {
            let choosenPortafolio = location.state !== null && location.state.portafolio !== undefined && location.state.portafolio !== null ? location.state.portafolio : "ModernVibe";
            const { default: NewComponent } = await import(`./../PoolFormularios/${choosenPortafolio}/${choosenPortafolio}`);
            setPortafolioComponent({ component: <NewComponent inputs={itemsNames} scrollToElement={sectionDetailNombre} 
                subGaleriaIndex={subGaleriaIndex} subCasoIndex={subCasoIndex} 
                subProyectoIndex={subProyectoIndex} subArticuloIndex={subArticuloIndex} preview={preview}
                ref={portfolioRef}/> });
            let isInfProject = location.state !== null && location.state.inf !== undefined && location.state.inf !== null ? location.state.inf : false;
            if(isInfProject){
                setShowSections(false);
                handleSectionDetail("Proyectos");
                setTabFirstValue("Proyectos");
                setTabItemSelected("Proyectos");
                const newPair = { titulo: `Proyecto`,horario:"",grupo:"", 
                        descripcion: '', imagen:"",
                        bloques:[{titulo:"Contexto",index:0,detalle:"",imagen:"",active:false,content:[]},
                                {titulo:"Objetivos",index:1,detalle:"",imagen:"",active:false,content:[]},
                                {titulo:"Planificacion",index:2,detalle:"",imagen:"",active:false,content:[]},
                                {titulo:"Desafios",index:3,detalle:"",imagen:"",active:false,content:[]},
                                {titulo:"Equipo",index:4,detalle:"",imagen:"",active:false,content:[]},
                                {titulo:"Herramientas",index:5,detalle:"",imagen:"",active:false,content:[]},
                                {titulo:"Implementacion",index:6,detalle:"",imagen:"",active:false,content:[]},
                                {titulo:"Resultados",index:7,detalle:"",imagen:"",active:false,content:[]},
                                {titulo:"Aprendizajes",index:8,detalle:"",imagen:"",active:false,content:[]}] };
                setGeneral('Proyectos','proyectos',[...seccionesData.Proyectos.proyectos, newPair]);
                setSubProyectoIndex(0);
                setSectionDetailNombre("Proyectos");
                setItemsNames(["Proyectos"]);
            }
        };
        
        getPortafolio();
    }, []);

    const moveItem = (dragIndex, dropIndex) => {
        const newItems = [...items];
        const draggedItem = newItems[dragIndex];
        newItems.splice(dragIndex, 1);
        newItems.splice(dropIndex, 0, draggedItem);
        setItems(newItems);

        const newItemsNames = [...itemsNames];
        const draggedItemNames = newItemsNames[dragIndex];
        newItemsNames.splice(dragIndex, 1);
        newItemsNames.splice(dropIndex, 0, draggedItemNames);
        setItemsNames(newItemsNames);
    };

    const handleSectionDetail= async (key)=>{
        // TODO refactorizar Sobre mi para que sea estandarizado
        const { default: NewComponent } = await import(`./Sections/Seccion${key==='Sobre mi'?"SobreMi":key}`);
        setSectionDetail({ component: <NewComponent enterSub={enterSub} ref={childRef} backClick={handleBackButton}/> });
        setSectionDetailNombre(key);
        setShowSectionDetail(true);
    }

    const enterSub=(i, sub)=>{
        setSectionDetailNombre(sub);
        portfolioRef.current.triggerChildFunction();
        switch(sub){
            case "Galeria":
                setSubGaleriaIndex(i);
                break;
            case "Casos":
                setSubCasoIndex(i);
                break;
            case "Proyectos":
                setSubProyectoIndex(i);
                break;
            case "Articulo":
                setSubArticuloIndex(i);
                break;
            default:
                break;
        }
    }

    const handleSectionDetailClose=()=>{
        setShowSectionDetail(false);
        handleToastr(`La sección ${sectionDetailNombre} ha sido guardada`);
        setSectionDetailNombre("");
        changeMenu(0);
    }

    const handleDescartarCambios = () => {
        setShowChangeSectionPopup(true);
      };

    const handleChangeMenu=(value)=>{
        if(value){
            if(showSectionDetail){
                setShowSectionDetail(false);
            }
            childRef.current.triggerChildFunction();
            setShowSectionDetail(false);
            setSectionDetailNombre("");
            changeMenu(submenuFuture);
        }
    }

    const changeMenu =(subMenu)=>{
        switch(subMenu){
            case 1:
                setSectionDetailNombre("Galeria");
                setSubCasoIndex(-1);
                setSubProyectoIndex(-1);
                setSubArticuloIndex(-1);
                break;
            case 2:
                setSectionDetailNombre("Casos");
                setSubGaleriaIndex(-1);
                setSubProyectoIndex(-1);
                setSubArticuloIndex(-1);
                break;
            case 3:
                setSectionDetailNombre("Proyectos");
                setSubGaleriaIndex(-1);
                setSubCasoIndex(-1);
                setSubArticuloIndex(-1);
                break;
            case 4:
                setSectionDetailNombre("Articulo");
                setSubGaleriaIndex(-1);
                setSubCasoIndex(-1);
                setSubProyectoIndex(-1);
                break;
            default:
                setSectionDetailNombre("");
                setSubGaleriaIndex(-1);
                setSubCasoIndex(-1);
                setSubProyectoIndex(-1);
                setSubArticuloIndex(-1);
                break;
        }
        setMenu(subMenu);
    }

    const enterSubGaleria = (index)=>{
        if(showSubGaleria)setSectionDetailNombre("Galeria");
        else setSectionDetailNombre("");
        setShowSubGaleria(!showSubGaleria);
        setSubGaleriaIndex(index);
    }

    const enterSubCaso = (index)=>{
        if(showSubCaso)setSectionDetailNombre("Casos");
        else setSectionDetailNombre("");
        setShowSubCaso(!showSubCaso);
        setSubCasoIndex(index);
    }

    const enterSubProyecto = (index)=>{
        if(showSubProyecto)setSectionDetailNombre("Proyectos");
        else setSectionDetailNombre("");
        setShowSubProyecto(!showSubCaso);
        setSubProyectoIndex(index);
    }

    const enterSubArticulo = (index)=>{
        if(showSubArticulo)setSectionDetailNombre("Articulo");
        else setSectionDetailNombre("");
        setShowSubArticulo(!showSubArticulo);
        setSubArticuloIndex(index);
    }

    const handleToastr = (value)=>{
        setShowToastr(true);
        setToastrText(value);
        setTimeout(()=>{
            setShowToastr(false);
            setToastrText("");
        },3500);
    }

    const deleteSectionCard=(value)=>{
        setShowEliminarPopup(false);
        if(!value)return;
        let valueToDelete = deleteSectionCardValue==='Sobre mi'?'SobreMi':deleteSectionCardValue;
        setGeneral('inputs',valueToDelete,false);
        handleClosePopup(seccionesData.inputs);
        handleToastr(`La sección ${deleteSectionCardValue} ha sido eliminada`);
    }

    const goToCheckout= async ()=>{
        //navigate('/checkout');
        setShowLoader(true);

        let pdfToPrint = null;
        // Get landing
        scrollElement();
        await processNewPage(pdfToPrint).then((returned)=>pdfToPrint=returned)
        
        // Get Subgalerias
        let cantSubgalerias = seccionesData.Galeria.galerias.length;
        for(let i = 0; i< cantSubgalerias; i++){
            enterSubGaleria(i);
            scrollElement();
            await processNewPage(pdfToPrint).then((returned)=>pdfToPrint=returned)
        }
        
        // Get Subcasos
        let cantSubcasos = seccionesData.Casos.casos.length;
        for(let i = 0; i< cantSubcasos; i++){
            enterSubCaso(i);
            scrollElement();
            await processNewPage(pdfToPrint).then((returned)=>pdfToPrint=returned)
        }
        
        // Get Subproyectos
        let cantSubproyectos = seccionesData.Proyectos.proyectos.length;
        for(let i = 0; i< cantSubproyectos; i++){
            enterSubProyecto(i);
            scrollElement();
            await processNewPage(pdfToPrint).then((returned)=>pdfToPrint=returned)
        }
        
        // Get Subarticulos
        let cantSubarticulos = seccionesData.Articulo.articulos.length;
        for(let i = 0; i< cantSubarticulos; i++){
            enterSubArticulo(i);
            scrollElement();
            await processNewPage(pdfToPrint).then((returned)=>pdfToPrint=returned)
        }
        
        pdfToPrint.save();

        setShowLoader(false);
        setShowThanksPopup(true);

    }

    const scrollElement=()=>{
        const parentElement = document.getElementById('parentElement');
        parentElement.scrollTo({
            top: parentElement.scrollHeight,
            left: 0,
            behavior: 'smooth',
        });
    }

    const processNewPage = async (pdfToPrint) => {
        await new Promise((resolve) =>
        setTimeout(() => {
            let input = document.getElementById('portfolio');

            Array.from(input.getElementsByClassName("slide-in-element-bottom")).forEach((element) => {
                element.classList = "slide-in-element-bottom present";
            });

            getPDF(input,pdfToPrint).then((pdf)=>{
                pdfToPrint = pdf;
                resolve(pdfToPrint)
            })
        }, 1000))
        return pdfToPrint;
      };

    const getPDF=(input,pdf)=>{
        return new Promise((resolve) => {
            const divHeight = input.clientHeight
            const divWidth = input.clientWidth
            html2canvas(input, { height: input.scrollHeight,allowTaint: true,useCORS:true})
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0);
                
                if(pdf===null) pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
                else pdf.addPage();
                var pdfWidth = pdf.internal.pageSize.getWidth();
                var pdfHeight = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                resolve(pdf); 
            }).catch((error) => {
                console.error('Error capturing element:', error);
                resolve(null);
            });
              
        });
        
    }

    const handleCloseForm=(value)=>{
        setShowForm(!showForm);
        if(value) goToCheckout();
        
        
    }

    const getForm=()=>{
        return <MvpForm close={handleCloseForm}></MvpForm>
    }

    const handleTabChange =(value)=>{
        setTabItemSelected(value);
        setShowVisualConfigurationDetail(false);
        switch(value){
            case 'Secciones':
                setPreview("Computer")
                setMenu(0);
                break;
            case 'Configuración':
                setMenu(5);
                setPreview("Special")
                break;
            default :
                setPreview("Computer")
                setMenu(0);
                break;
        }
    }

    const handleSectionCard=(value, item)=>{

        switch(value){
            case 'Editar':
                handleSectionDetail(item)
                break;
            case 'Eliminar':
                setDeleteSectionCardValue(item)
                setShowEliminarPopup(true);
                break;
            default :
                break;
        }
    }

    const getIcon=(value)=>{
        switch(value){
            case "Banner":
                return BannerIcon;
            case "Sobre mi":
                return AboutMeIcon;
            case "Servicios":
                return ServicesIcon;
            case "Experiencia":
                return ExperienceIcon;
            case "Educacion":
                return EducationIcon;
            case "Habilidades":
                return SkillsIcon;
            case "Contacto":
                return ContactIcon;
            case "Testimonios":
                return TestimonyIcon;
            case "Galeria":
                return GaleryIcon;
            case "Proyectos":
                return ProjectIcon;
            case "Casos":
                return CaseIcon;
            case "Articulo":
                return ArticleIcon;
        }
    }

    return (
      <div className='col'>
        {showLoader && (
            <Loader></Loader>
        )}
        {showForm && (
            <GeneralForm close={handleCloseForm} children={getForm}></GeneralForm>
        )}
        {showThanksPopup && 
            <Popup title = {`¡Gracias por elegir Profolio para crear tu portafolio! `} 
                    text={`¡Agradecemos tu tiempo y valiosos comentarios para hacer de Profolio una plataforma aún mejor!`}
                    close={()=>{
                        
                        setShowThanksPopup(false);
                    }}
                    btn2Config = {{text:"Entendido"}}>
            </Popup>
        }
        {showToastr && (
            <Toastr text={toastrText}></Toastr>
        )}
        {showSections && (
            <div>
                <div className="blur-background"></div>
                <SectionPopUp
                    iscardState={seccionesData.inputs}
                    onClose={handleClosePopup}
                />
            </div>
        )}
        {showEliminarPopup && (
            <Popup title = {`Confirmación de eliminación`} 
                    text={`Recuerda que esta acción es irreversible y eliminará toda la información asociada a la seccion  ${deleteSectionCardValue}. ¿Estás seguro de que deseas continuar?`}
                    close={deleteSectionCard}
                    btn1Config = {{text:"Cancelar"}}
                    btn2Config = {{text:"Confirmar eliminación", error:true}}>
            </Popup>
        )}
        {showChangeSectionPopup && (
            <Popup  title = {`Salir de ${sectionDetailNombre}`} 
                    text={`¿Estás seguro de que deseas salir de ${sectionDetailNombre} y descartar los cambios? Esta acción no se puede deshacer y perderás cualquier información no guardada.`}
                    close={(value)=>{
                        handleChangeMenu(value);
                        setShowChangeSectionPopup(false)
                    }}
                    btn1Config = {{text:"Cancelar"}}
                    btn2Config = {{text:"Salir y descartar cambios"}}>
            </Popup>
        )}
        <div className='col-m12 ' style={{
            flexGrow: 1,
            height: `${window.innerHeight}px `, 
        }}>
            <BuilderTopBar buttonCallback={()=>setShowForm(!showForm)} previewCallback={(value)=>setPreview(value)} preview={preview}></BuilderTopBar>
            <div className='row-m12' style={{ maxHeight : "calc(100vh - 57px)"}}>
                <div className='card-personalizacion'  >
                    <Tab items={[tabFirstValue,"Configuración"]} itemSelected={tabItemSelected} click={handleTabChange}></Tab>
                    {menu===0  && (
                        <div className='col-m12 gap16' style={{ overflowY : "auto"}}>
                            {sectionDetailNombre==='' &&
                                <p className='subtitle_2'>Lista de Secciones</p>
                            }
                            <div className='scrollable col'>
                                {!showSectionDetail?(
                                    <div className='col-m12 gap16'>
                                        <p className='subtitle_2'>Selecciona una sección para editarla:</p>
                                        <div className='col gap8'>
                                            <Droppable>
                                            {itemsNames.map((item, index) => (
                                                <Draggable 
                                                key={index}
                                                id={index}
                                                index={index}
                                                moveItem={moveItem}>
                                                    <SectionCard text={item} icon={getIcon(item)} clickDetails={value=>handleSectionCard(value,item)} click={()=>handleSectionDetail(item)}></SectionCard>
                                                </Draggable>
                                            ))}
                                            </Droppable>
                                            <div className='row'>
                                                <Button texto={"Agregar Sección"} tipo={2} size={"s"} leftIcon={PlusIcon} click={(e)=>{setShowSections(true)}}></Button>
                                            </div>
                                        </div>
                                    </div>
                                ):(
                                    <div className='col-m12'>
                                        {sectionDetail.component}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {menu===5 && (
                        <div className='col-m12 gap16 scrollable'>
                            <VisualConfiguration showSectionDetail={showVisualConfigurationDetail} setShowSectionDetail={setShowVisualConfigurationDetail}></VisualConfiguration>
                        </div>
                    )}
                    {showSectionDetail && menu===0 && (
                        <div className='col gap8' style={{width:"100%"}}>
                            <div className='row gap8'>
                                <div className='col-m6' >
                                    <Button texto={"Regresar"} tipo={2} fill={true} click={handleDescartarCambios}></Button>
                                </div>
                                <div className='col-m6' >
                                    <Button texto={"Guardar"} tipo={1} fill={true} click={handleSectionDetailClose}></Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {showSectionDetail && menu===5 && (
                        <div className='col gap8' style={{width:"100%"}}>
                            <div className='row gap8'>
                                <div className='col-m12' >
                                    <Button texto={"Regresar"} tipo={1} fill={true} click={()=>setShowSectionDetail(false)}></Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='col-m12 stickyPosition'>
                    <div className='col-m12 center'>
                        <div className='vistaPrevia'>
                                <div className='col-m12' style={{ maxHeight : "calc(100vh - 57px)", width : "calc(100% - 5px)"}} id='parentElement'>
                                    
                                    {portafolioComponent.component &&
                                        <div id="portfolio" className='col-m12' style={{ alignItems:"center"}} >
                                            {React.cloneElement(portafolioComponent.component,
                                                { inputs:itemsNames, scrollToElement:sectionDetailNombre, 
                                                subGaleriaIndex:subGaleriaIndex,subCasoIndex: subCasoIndex ,
                                                subProyectoIndex: subProyectoIndex, subArticuloIndex: subArticuloIndex, preview:preview })}
                                        </div>
                                    }
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
}

export default Formulario;