import React from 'react';
import { Route, Routes  } from 'react-router-dom';
import Portafolios from './Portafolios';
import ModernVibe from './PoolFormularios/ModernVibe/ModernVibe';
import CardiLux from './PoolFormularios/CardiLux/CardiLux';

const PortafoliosRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Portafolios />} />
      <Route path="/ModernVibe" element={<ModernVibe preview={"Prod"} inputs={["Banner",
                                                                                "SobreMi",
                                                                                "Servicios",
                                                                                "Habilidades",
                                                                                "Educacion",
                                                                                "Experiencia",
                                                                                "Contacto",
                                                                                "Galeria",
                                                                                "Proyectos",
                                                                                "Casos",
                                                                                "Articulo"]} 
                                                                                subGaleriaIndex={-1} 
                                                                                subCasoIndex={-1} 
                                                                                subProyectoIndex={-1}  
                                                                                subArticuloIndex={-1} />} />
      <Route path="/CardiLux" element={<CardiLux preview={"Prod"} inputs={["Banner",
                                                                                "SobreMi",
                                                                                "Servicios",
                                                                                "Habilidades",
                                                                                "Educacion",
                                                                                "Experiencia",
                                                                                "Contacto",
                                                                                "Galeria",
                                                                                "Proyectos",
                                                                                "Casos",
                                                                                "Articulo"]} 
                                                                                subGaleriaIndex={-1} 
                                                                                subCasoIndex={-1} 
                                                                                subProyectoIndex={-1}  
                                                                                subArticuloIndex={-1} />} />
    </Routes>
  );
};

export default PortafoliosRouter;
