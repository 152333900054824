import React, { useContext } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';

const SubProyecto = (props)=>{
    const { seccionesData } = useContext(InputValuesContext);
    const { preview,color,subProyectoIndex} = props;

    return (
        <div id="CircleVibeSubProyecto" className={`generalContainer${preview ? preview : 'Prod'} gap32`}  style={{ minHeight : "calc(100vh - 204px)"}}>
            <div className={`col-m12${preview ? preview : 'Prod'} gap12`}>
                <div className='titleLarge' style={{color: color}}>{seccionesData.Proyectos.proyectos[subProyectoIndex].titulo}</div>
                <div className='body'>{seccionesData.Proyectos.proyectos[subProyectoIndex].descripcion}</div>
                {seccionesData.Proyectos.proyectos[subProyectoIndex].imagen!=="" && (
                    <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={seccionesData.Proyectos.proyectos[subProyectoIndex].imagen} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                )}
            </div>
            <div className={`col-m12${preview ? preview : 'Prod'} gap12`}>
                {seccionesData.Proyectos.proyectos[subProyectoIndex].bloques.map((item,index)=>(
                    <div key={index}>
                        <div className='title' style={{color: color}}>{item.titulo.value}</div>
                        <div className='subtitle' style={{color: color}}>{item.subtitulo.value}</div>
                        <div className='body'>{item.descripcion.value}</div>
                        {item.imagen.value!=="" && (
                            <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={item.imagen.value} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                        )}
                        {item.lista.value!=="" && 
                            <ul>
                                {item.lista.value.split('\n').map((subitem, index) => (
                                    <li key={index} className='cta'>{subitem}</li>
                                ))}
                            </ul>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SubProyecto;