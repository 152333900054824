import React from 'react';
import './Button.css';

// Params
// texto : The text is going to be shown in the button
// tipo : The kind of button, default is Primary
// leftIcon : If present, the icon that is going to be on the left side of the text
// rightIcon : If present, the icon that is going to be on the right side of the text
// size : The size og the button, default is m
// disabled : Indicates if the button should be presented as disabled or not

const Button = (props)=>{

    const {texto, tipo, error, fill, click, leftIcon: LeftIcon, rightIcon : RightIcon, size, disabled} = props;

    const handleClick=(e)=>{
        e.stopPropagation();
        click(e);
    }

    const getType=(type)=>{
        switch(type){
            case 1 : return "Primary";
            case 2 : return "Secondary";
            case 3 : return "Terciary";
            default: return "Primary";
        }
    }

    // TODO mejorar interacciones de los iconos 

    return (
        <div className={`btn${getType(tipo)}_${size===undefined?'m':size} 
                            ${disabled?'disabled':''} 
                            ${fill?'fill':''} 
                            ${error?'error':''}
                            ${RightIcon?"btnRightIcon":""}
                            ${LeftIcon?"btnLeftIcon":""}`} onClick={(e)=>handleClick(e)}>
            {LeftIcon &&
                <LeftIcon></LeftIcon>
            }
            <div className={`btnText_${size===undefined?'m':size} btnText${getType(tipo)}`}>{texto}</div>
            {RightIcon &&
                <RightIcon></RightIcon>
            }
        </div>
    )
}

export default Button;