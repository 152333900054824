import React, { useEffect, useState } from 'react';

const Draggable = ({ id, index, moveItem, children }) => {

  
  const [draggingPosition, setDraggingPosition] = useState({ offsetX: 0, offsetY: 0 });
  const [isDragging, setDragging] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        setDraggingPosition({ x: e.clientX, y: e.clientY });
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging]);


  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', id);
    
    setDragging(true);
    const { offsetX, offsetY } = e.nativeEvent;
    setDraggingPosition({ x: e.clientX - offsetX, y: e.clientY - offsetY });

    document.body.style.cursor = 'grabbing';

    e.target.parentNode.parentNode.classList.add('dragging');
    moveItem(index, index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.target.parentNode.parentNode.classList.remove('drag-over');
    const draggableId = e.dataTransfer.getData('text/plain');
    moveItem(draggableId, index);
  };

  const handleDragEnd = (e) => {
    setDragging(false);
    document.body.style.cursor = 'auto';
    e.target.parentNode.parentNode.classList.remove('dragging');
  };

  return (
    <div className="col center input-container1">
      <div className='draggable col-m12 center' 
      style={{
        top: draggingPosition.y,
        left: draggingPosition.x,
      }}>
        <div className='col-m12 center'
          draggable
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragEnd={handleDragEnd}
          
        >
          <div className='col-m12'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Draggable;
