import React, { useState } from 'react';
import './RadioButton.css'
import RadioButton from './RadioButton';

const RadioButtonContainer = (props)=>{
    // options = [ { key, value } ]
    // selected = key that is selected
    // clickRadioButton =  function to call to handle the external values (change the selected value)
    // isRow : Change the direction the component is display
    const {options, selected, clickRadioButton, isRow, type} = props;
    const handleClick = (key,value) =>{
        clickRadioButton(key,value)
    }

    return (
        <div className='col gap8' style={{ flexDirection : isRow?'row':'column'}}>
            {options.map((item,index)=>(
                <RadioButton key={index} click={()=>handleClick(item.key,item.value)} selected={selected===item.key} type={type} icon={item.icon}>
                    <label className={`inputLabel ${selected===item.key?"radioButtonSelected":""}`}>{item.value}</label>
                </RadioButton>
            ))}
        </div>
    )
}

export default RadioButtonContainer;