import React, { useContext, useState } from 'react';
import './VisualConfiguration.css';
import SectionCard from '../../../general-components/SectionCard/SectionCard';
import ColorsIcon from '../../../util/constants/icons/ColorsIcon';
import TypographyIcon from '../../../util/constants/icons/TypographyIcon';

const VisualConfiguration =(props)=>{

    const {showSectionDetail, setShowSectionDetail} = props;

    const [sectionDetail, setSectionDetail] = useState({ component: null });

    const handleSectionDetail= async (key)=>{
        const { default: NewComponent } = await import(`./../${key}/${key}`);
        setSectionDetail({ component: <NewComponent back={()=>setShowSectionDetail(false)}/> });
        setShowSectionDetail(true);
    }

    const handleSectionCard=(value, item)=>{

        switch(value){
            case 'Editar':
                handleSectionDetail(item)
                break;
            case 'Eliminar':
                break;
            default :
                break;
        }
    }

    return (
        <div className='col'>
            {showSectionDetail?(
                <div className='visualConfigurationDetailContainer'>
                    {sectionDetail.component}
                </div>
            ):(
                <div className='visualConfigurationListContainer'>
                    <p className='subtitle_2'>Lista de Configuraciones</p>
                    <div className='col gap8'>
                        <SectionCard text={"Colores"} icon={ColorsIcon} clickDetails={value=>handleSectionCard(value,"Colors")} click={()=>handleSectionDetail("Colors")}></SectionCard>
                        <SectionCard text={"Tipografía"} icon={TypographyIcon} clickDetails={value=>handleSectionCard(value,"Typography")} click={()=>handleSectionDetail("Typography")}></SectionCard>
                    </div>
                </div>
            )}
        </div>
    )
}

export default VisualConfiguration;