import './../Icon.css';
const ContactIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path d="M40.3636 12H7.63636C6.73263 12 6 12.7163 6 13.6V15.8502C6 16.4357 6.32709 16.9744 6.85279 17.2548L23.2164 25.9821C23.7048 26.2426 24.2952 26.2426 24.7836 25.9821L41.1472 17.2548C41.6729 16.9744 42 16.4357 42 15.8502V13.6C42 12.7163 41.2674 12 40.3636 12Z" />
            <path d="M23.2164 27.5821L8.41994 19.6906C7.32951 19.1091 6 19.8808 6 21.0953V34.4C6 35.2837 6.73263 36 7.63636 36H40.3636C41.2674 36 42 35.2837 42 34.4V21.0953C42 19.8808 40.6705 19.1091 39.5801 19.6906L24.7836 27.5821C24.2952 27.8426 23.7048 27.8426 23.2164 27.5821Z"/>
        </svg>
    )
}

export default ContactIcon;



