import './../Icon.css';
const EducationIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path d="M7.94057 4.50793L1.15689 6.35461C0.947703 6.41156 0.947703 6.69401 1.15689 6.75096L7.94057 8.59764C7.97941 8.60822 8.02059 8.60822 8.05943 8.59764L14.8431 6.75096C15.0523 6.69401 15.0523 6.41156 14.8431 6.35461L8.05943 4.50793C8.02059 4.49736 7.97941 4.49736 7.94057 4.50793Z" />
            <path d="M7.94754 9.11658L3.94245 8.16259C3.80592 8.13006 3.67366 8.22845 3.67366 8.36253V11.5548C3.67366 11.6445 3.7358 11.7244 3.8256 11.7512C7.21812 12.7663 9.09326 12.7323 12.1783 11.7528C12.2662 11.7249 12.3263 11.646 12.3263 11.5576V8.36253C12.3263 8.22845 12.1941 8.13006 12.0576 8.16259L8.05246 9.11658C8.01802 9.12479 7.98198 9.12479 7.94754 9.11658Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.85781 11.3367C1.93753 11.4886 2.16505 11.4886 2.24477 11.3367L2.55629 10.743C2.57939 10.699 2.5852 10.6486 2.57267 10.6009L2.32814 9.66895C2.32385 9.65261 2.32168 9.63582 2.32168 9.61897V7.78941C2.32168 7.67558 2.22484 7.5833 2.10537 7.5833H1.99721C1.87774 7.5833 1.78089 7.67558 1.78089 7.78941V9.61897C1.78089 9.63582 1.77872 9.65261 1.77443 9.66895L1.5299 10.6009C1.51738 10.6486 1.52319 10.699 1.54628 10.743L1.85781 11.3367Z"/>
        </svg>
    )
}

export default EducationIcon;



