import React, { useEffect, useRef, useState } from 'react';
import './Input.css'
import ClickOutsideClose from '../../util/functions/General/ClickOutsideClose';

const Input = (props)=>{

    const {name, label, placeHolder, helperText, value, changer, disabled, error, onFocus, onBlur} = props;
    const textareaRef = useRef(null);
    const [ isActive, setIsActive] = useState(false)

    ClickOutsideClose(textareaRef,()=>setIsActive(false));
        
    const handleChange = (event) => {
        changer(event);
        adjustTextareaHeight(textareaRef.current);
    };

    useEffect(() => {
        adjustTextareaHeight(textareaRef.current);
    }, []);

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto'; 
        textarea.style.height = `${textarea.scrollHeight}px`; 
      };

    return (
        <div className={`inputContainer ${error?"error":""} ${isActive?"active":""}`} onClick={()=>setIsActive(true)}>
            <label className='inputLabel'>{label}</label>
            <textarea className='inputAutoSize'
                rows={1}
                name={name}
                value={value}
                placeholder={placeHolder}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled===null?false:disabled}
                ref={textareaRef}>
            </textarea>
            {helperText && 
                <label className='helperText'>{helperText}</label>
            }
        </div>
    )
}

export default Input;