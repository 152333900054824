import React, { useContext, useState } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';
import SlideInElementBottom from '../../../util/SlideInElementBottom';
import { useParams, useNavigate   } from 'react-router-dom';
import SubGaleria from './SubGaleria';
import TopBar from './TopBar';
import Menu from './Menu';

const GaleriaPage = ()=>{
    const { seccionesData } = useContext(InputValuesContext);
    const [preview,setPreview] = useState("Prod");
    const { paramName } = useParams();
    const navigate  = useNavigate ();

    const goToGaleriaWithParam = (index) => {
        navigate (`/circlevibe/galeria/${index}`);
      };


    const handleClick =(index)=>{
        if(preview==='Prod'){
            if(index===-1){
                navigate (`/circlevibe/galeria/all`);
            }else{
                navigate (`/circlevibe/galeria/${index}`);
            }
        }
    }
    return (
        <div id="CircleVibeGaleriaPage" className='pageContainer' style={{
            background: `${seccionesData.Config.mode==="light"? "#f8f8f8":"#101010"}`,
          }}>
            <TopBar color={`${seccionesData.Config.mode==="light"? "#f8f8f8":"#101010"}`}
                    logoString={seccionesData.Config.logo}
                    mode={seccionesData.Config.mode}></TopBar>
            <Menu click={handleClick} optionSelected={paramName==='all'?-1:paramName}></Menu>
            {paramName==='all'?(
                <div  className={`generalContainer${preview ? preview : 'Prod'}`} >
                    <div className={`col-m12${preview ? preview : 'Prod'} center`}>
                        <div className={`col-m12${preview ? preview : 'Prod'} center`}>
                            <SlideInElementBottom>
                                <div className='galeriaGeneralCircleVibe center'>
                                    <div className={`col-m8${preview ? preview : 'Prod'} center`}>
                                        <div className='title'
                                            style={{color: seccionesData.Config.color}}
                                        >{seccionesData.Galeria.titulo}</div>
                                        <div className='body'>{seccionesData.Galeria.descripcion===''?seccionesData.Galeria.descripcionPreview:seccionesData.Galeria.descripcion}</div>
                                    </div>
                                </div>
                            </SlideInElementBottom>
                        </div>    
                        {seccionesData.Galeria.galerias.length<1?(
                            <div className={`row-m12${preview ? preview : 'Prod'} center gap32 wrap-row `}>
                                {seccionesData.Galeria.galeriaPreview.map((item, index) => (
                                    <div key={index} className={`center`} style={{ width : "30%"}}>
                                        <SlideInElementBottom>
                                            <div className={`center galeriaGeneralImageContainerCircleVibe clickeable`} style={{position:"relative"}}>
                                                <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={item.imagen} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                                                <div className='galeriaLayer'></div>
                                                <div className='galeriaImageTextCircleVibe center gap8'>
                                                    <div className='subtitle ' style={{position:"relative"}}>{item.titulo}</div>
                                                    <div className='body ' style={{position:"relative"}}>{item.descripcion}</div>
                                                </div>
                                            </div>
                                        </SlideInElementBottom>
                                    </div>
                                ))}
                            </div>
                        ):(
                            <div className={`row-m12${preview ? preview : 'Prod'} center gap32 wrap-row`}>
                                {seccionesData.Galeria.galerias.map((item, index) => (
                                    <div key={index} className={`center`} style={{ width : "30%"}}>
                                        <SlideInElementBottom>
                                            <div className={`center galeriaGeneralImageContainerCircleVibe clickeable`} style={{position:"relative"}} onClick={()=>goToGaleriaWithParam(index)}>
                                                {item.imagenes.length>0  && (
                                                    <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={item.imagenes[0]} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                                                )}
                                                <div className='galeriaLayer'></div>
                                                <div className='galeriaImageTextCircleVibe center gap8'>
                                                    <div className='subtitle ' style={{position:"relative"}}>{item.titulo}</div>
                                                    <div className='body ' style={{position:"relative"}}>{item.descripcion}</div>
                                                </div>
                                            </div>
                                        </SlideInElementBottom>
                                    </div>
                                ))}
                            </div>
                        )} 
                    </div>
                </div>
            ):(
                <SubGaleria subGaleriaIndex={paramName} preview={preview}></SubGaleria>
            )}
        </div>
    )
}

export default GaleriaPage;