import React from 'react';

const Toastr =(props)=>{
    const {text} = props;
    const rectangule = {
        width: '4px',
        alignSelf: 'stretch',
        borderRadius: 'var(--radio-border-rb-sm, 8px)',
        background: 'var(--text-primary)'
      };
    return (
        <div className="toastr-container">
          <div className="toastr">
            <div style={rectangule}></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" className='custom-fill'>
                <mask id="mask0_481_8247" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                <rect y="0.707031" width="24" height="24" />
                </mask>
                <g mask="url(#mask0_481_8247)">
                <path d="M10.6 17.307L17.65 10.257L16.25 8.85703L10.6 14.507L7.75 11.657L6.35 13.057L10.6 17.307ZM12 22.707C10.6167 22.707 9.31667 22.4445 8.1 21.9195C6.88333 21.3945 5.825 20.682 4.925 19.782C4.025 18.882 3.3125 17.8237 2.7875 16.607C2.2625 15.3904 2 14.0904 2 12.707C2 11.3237 2.2625 10.0237 2.7875 8.80703C3.3125 7.59036 4.025 6.53203 4.925 5.63203C5.825 4.73203 6.88333 4.01953 8.1 3.49453C9.31667 2.96953 10.6167 2.70703 12 2.70703C13.3833 2.70703 14.6833 2.96953 15.9 3.49453C17.1167 4.01953 18.175 4.73203 19.075 5.63203C19.975 6.53203 20.6875 7.59036 21.2125 8.80703C21.7375 10.0237 22 11.3237 22 12.707C22 14.0904 21.7375 15.3904 21.2125 16.607C20.6875 17.8237 19.975 18.882 19.075 19.782C18.175 20.682 17.1167 21.3945 15.9 21.9195C14.6833 22.4445 13.3833 22.707 12 22.707Z"/>
                </g>
            </svg>
            <p className='subtitle_1'>{text}</p>
          </div>
        </div>
    )
}

export default Toastr;