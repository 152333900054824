import React, { useState } from 'react';
import { Link as Linker,useNavigate} from 'react-router-dom';
import logo from './../../assets/icons/profolio-logotipo-footer.svg';
import paypalLogo from './../../assets/icons/PaypalLogo.svg';
import Breadcrumb from '../../general-components/Breadcrumb/Breadcrumb';
import Input from '../../general-components/Input/Input';
import Button from '../../general-components/Button/Button';
import RadioButtonContainer from '../../general-components/RadioButton/RadioButtonContainer';

const PaypalLogo = ()=>{
    return (
        <img src={paypalLogo} alt=''></img>
    )
}

const ArrowBackIcon = ()=>{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <mask id="mask0_1832_1496" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                <rect width="16" height="16" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_1832_1496)">
                <path d="M8.00033 13.3333L2.66699 7.99996L8.00033 2.66663L8.95033 3.59996L5.21699 7.33329H13.3337V8.66663H5.21699L8.95033 12.4L8.00033 13.3333Z" fill="white"/>
            </g>
        </svg>
    )
}

const Checkout = ()=>{
    const navigate = useNavigate ();

    const [step,setStep] = useState(0);
    const [textList, setTextList] = useState(["Pago","Confirmación"]);
    const [cupon, setCupon] = useState("");
    const [cuponAplicado, setCuponAplicado] = useState(false);
    const [radioButtonSelected, setRadioButtonSelected] = useState(0);
    const [precio,setPrecio] = useState(202.8);
    const [rebaja,setRebaja] = useState(0.2);

    const handleClick = (index,value) => {
        setRadioButtonSelected(index);
    };

    const handleAplicar=()=>{
        // TODO validar cupones validos
        if(cupon==='PRO23'){
            setCuponAplicado(true)
        }
        // TODO show error de cupon invalido
    }

    const handleRemoveCupon=()=>{
        setCupon("");
        setCuponAplicado(false);
    }

    const handlePagar=()=>{
        // TODO call paypal
    }

    return (
        <div className='col-m12 center'>
            <div className='row-m12 center'>
                <div className='card-checkout'>
                    <div className='col-m12 center'>
                        <div className='row-m12 space-between'>
                            <Linker to="/">
                                <img src={logo} alt='' className='logo'></img>
                            </Linker>
                            <div className='col center'>
                                <Breadcrumb step={step} textList={textList}></Breadcrumb>
                            </div>
                            <Button texto={"Regresar"} tipo={2} leftIcon={ArrowBackIcon} click={()=>navigate('/formulario')}></Button>
                        </div>
                    </div>
                    <div className='col-m12'>
                        <div className='space-between'>
                            <h4 className='heading_4'>Página de pago</h4>
                        </div>
                    </div>
                    <div className='row-m12 gap32 center'>
                        <div className='col-m6 gap24'>
                            <div className='col gap8'>
                                <p className='subtitle_1'>Selecciona un método de pago:</p>
                                <div className='col gap12'>
                                <RadioButtonContainer clickRadioButton={handleClick} selected={radioButtonSelected} options={
                                    [{key:0,value:"",icon:PaypalLogo}]}>
                                </RadioButtonContainer>
                                </div>
                            </div>
                            {!cuponAplicado?(
                                <div className='col gap8'>
                                    <p className='subtitle_1'>¿Tienes un cupón de descuento?</p>
                                    <div className='row gap16'>
                                        <Input label={"Cupón de descuento"}
                                                name={"cupon"}
                                                value={cupon} 
                                                changer={(event)=>setCupon(event.target.value)}></Input>
                                        <div className='col' style={{justifyContent:"end"}}>
                                            <Button texto={"Aplicar"} tipo={3} click={handleAplicar}></Button>
                                        </div>
                                    </div>
                                </div>
                            ):(
                                <div className='col gap8'>
                                    <p className='subtitle_1'>Cupón Aplicado</p>
                                    <div className='row space-between'>
                                        <p className='subtitle_1'>{cupon}</p>
                                        <div className='row gap8 center'>
                                            <p className='subtitle_1' style={{color:"#5DAA60"}}>{rebaja*100}% de Descuento</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" className='error' onClick={handleRemoveCupon}>
                                                <mask id="mask0_565_878" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
                                                    <rect x="0.481934" y="0.158203" width="16" height="16" fill="#D9D9D9"/>
                                                </mask>
                                                <g mask="url(#mask0_565_878)">
                                                    <path d="M7.0707 11.1426L8.80404 9.40925L10.5374 11.1426L11.4707 10.2092L9.73737 8.47591L11.4707 6.74258L10.5374 5.80924L8.80404 7.54258L7.0707 5.80924L6.13737 6.74258L7.8707 8.47591L6.13737 10.2092L7.0707 11.1426ZM5.4707 14.1426C5.10404 14.1426 4.79015 14.012 4.52904 13.7509C4.26793 13.4898 4.13737 13.1759 4.13737 12.8092V4.14258H3.4707V2.80924H6.80404V2.14258H10.804V2.80924H14.1374V4.14258H13.4707V12.8092C13.4707 13.1759 13.3401 13.4898 13.079 13.7509C12.8179 14.012 12.504 14.1426 12.1374 14.1426H5.4707ZM12.1374 4.14258H5.4707V12.8092H12.1374V4.14258Z"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='col-m6'>
                            <div className='card-checkout-inner'>
                                <div className='row gap8 center'>
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className='custom-fill2'>
                                        <rect width="36" height="36" rx="18" fill="#F8F8F8"/>
                                        <g clipPath="url(#clip0_1161_5060)">
                                        <path d="M17.5 22.5H10V30H17.5V22.5Z" fill="#262626"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M18.5 21.5H23.5C24.8807 21.5 26 20.4324 26 19.1154V8.38461C26 7.06763 24.8807 6 23.5 6H18.5V21.5Z" fill="#262626"/>
                                        <path d="M17.5 6H10V21.5H17.5V6Z" fill="#262626"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_1161_5060">
                                        <rect width="16" height="24" fill="white" transform="translate(10 6)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                    <div className='col gap4'>
                                        <p className='body_s'>Profolio Plan Premium - Pago anual</p>
                                        <p className='subtitle_1'>${precio}</p>
                                        <span className='caption'>Incluye 1 portafolio</span>
                                    </div>
                                </div>
                                <div className='line2'></div>
                                <div className='row-m12 space-between'>
                                    <p className='body_s'>Subtotal:</p>
                                    <p className='body_s'>${precio}</p>
                                </div>
                                {cuponAplicado &&
                                    <div className='row-m12 space-between'>
                                        <p className='body_s' style={{color:"#5DAA60"}}>Descuento({cupon})</p>
                                        <p className='body_s' style={{color:"#5DAA60"}}>-${precio*rebaja}</p>
                                    </div>
                                }
                                <div className='line2'></div>
                                <div className='row-m12 space-between'>
                                    <p className='body_s'>Total:</p>
                                    <p className='body_s'>${precio-(cuponAplicado?precio*rebaja:0)}</p>
                                </div>
                                <Button texto={"Continuar"} tipo={1} click={handlePagar}></Button>
                                <p className='body_s'>Al hacer click en “Continuar”, admito que he leído y aceptado los Términos y Condiciones incluida la Política de privacidad. También he leído y acepto Políticas de Reembolso.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkout;