import React, { useState } from 'react';
import './SectionCard.css';
import Dots3 from '../../util/constants/icons/Dots3Icon';
import Selector from '../Selector/Selector';

const SectionCard = (props)=>{

    const {icon: Icon, text, click, clickDetails} = props;

    const [openSelector, setOpenSelector] = useState(false);
    const [sectionCardContainerHover,setSectionCardContainerHover] = useState(false);

    const handleClick=()=>{
        setOpenSelector(!openSelector);
    }

    const handleClose=(value)=>{
        clickDetails(value)
        setOpenSelector(false)
    }

    return (
        <div className={`sectionCardContainer clickeable ${sectionCardContainerHover===false? "sectionCardContainerHover" : ""}`}  onClick={(e)=>{e.stopPropagation();click();}}>
            {openSelector && 
                <Selector options={[
                                {text:"Editar", icon:"EditIcon"},
                                {text:"Eliminar", icon:"AddCircleIcon"}
                            ]}
                            handleHover={(value)=>setSectionCardContainerHover(value)}
                            close={()=>setOpenSelector(false)}
                            handleClose={handleClose}
                            type={1}
                ></Selector>
            }
            <div className='sectionCardContent'>
                <div className='sectionCardSections'>
                    {Icon && <Icon size={"s"}></Icon>}
                    <p className='subtitle_2'>{text}</p>
                </div>
                <Dots3 click={(e)=>{e.stopPropagation();handleClick();} } size={'s'}></Dots3>
            </div>
        </div>
    )
}

export default SectionCard;