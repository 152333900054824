import './../Icon.css';
const BannerIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.5 10C6.01472 10 4 12.0147 4 14.5V32.5C4 34.9853 6.01472 37 8.5 37H38.5C40.9853 37 43 34.9853 43 32.5V14.5C43 12.0147 40.9853 10 38.5 10H8.5ZM23.5 23.5C25.9853 23.5 28 21.4853 28 19C28 16.5147 25.9853 14.5 23.5 14.5C21.0147 14.5 19 16.5147 19 19C19 21.4853 21.0147 23.5 23.5 23.5ZM19.927 24.25C19.0748 24.25 18.2957 24.7315 17.9146 25.4938L16.4146 28.4938C15.6666 29.9898 16.7544 31.75 18.427 31.75H28.5729C30.2456 31.75 31.3334 29.9898 30.5854 28.4938L29.0854 25.4938C28.7043 24.7315 27.9252 24.25 27.0729 24.25H19.927Z"/>
        </svg>
    )
}

export default BannerIcon;



