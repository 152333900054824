import React, { useContext, useEffect, useState } from 'react';
import './Custom.css';
import '../Displayer.css';
import { InputValuesContext } from '../../../util/InputValuesContext';
import SlideInElementBottom from '../../../util/SlideInElementBottom';
import TopBar from './TopBar';

const CircleVibeProd = ()=>{
    const { seccionesData, setGeneral } = useContext(InputValuesContext);
    const [items, setItems] = useState([]);
    const [preview, setPreview] = useState("Prod");

    useEffect(() => {
        // Set Tipografia
        document.documentElement.style.setProperty('--tipografia-formulario', seccionesData.Config.tipografia);
        // Set theme
        if(seccionesData.Config.mode==="light"){
            document.documentElement.style.setProperty('--primarycolor-formulario', "#f8f8f8");
            document.documentElement.style.setProperty('--secondarycolor-formulario', "#1b1b1b");
        }else{
            document.documentElement.style.setProperty('--primarycolor-formulario', "#1b1b1b");
            document.documentElement.style.setProperty('--secondarycolor-formulario', "#f8f8f8");
        }
        // Set secciones
        const updateItems = async () => {
            try {
                let importPromises = []
                Object.keys(seccionesData.inputs).forEach((key) => {
                    //if(seccionesData.inputs[key])      
                    //{
                        // TODO refactorizar Sobre mi para que sea estandarizado
                        importPromises.push( import(`./${key==='Sobre mi'?'SobreMi':key}`));
                    //}
                });
                const importedComponents = await Promise.all(importPromises);
                let newItems = [];
                const updatedItems = newItems.concat(
                    importedComponents.map(({ default: NewComponent }) => (
                        <NewComponent preview={preview} color={seccionesData.Config.color} linearBg={getLinearBackground()} key={Math.random()} />
                    ))
                );
                setItems(updatedItems);
            } catch (error) {
                console.error('Error loading components:', error);
            }
        };
        updateItems();
    }, []);

    const hexToRgb= (hex)=>{
      hex = hex.replace(/^#/, '');
      const red = parseInt(hex.substring(0, 2), 16);
      const green = parseInt(hex.substring(2, 4), 16);
      const blue = parseInt(hex.substring(4, 6), 16);
  
      return { red, green, blue };
    }

    const getLinearBackground=()=>{
        return `${seccionesData.Config.mode==="light"? "#ffffff":"#1b1b1b"}`
    }
    
    return (
        <div style={{ width :"100%", display:"flex", flexDirection:"column" , background : seccionesData.Config.mode==='dark'?'#1f1f1f':"#ffffff"}}>
            <TopBar color={`${seccionesData.Config.mode==="light"? "#f8f8f8":"#101010"}`}
                    logoString={seccionesData.Config.logo}
                    mode={seccionesData.Config.mode}></TopBar>
            <div className='col-m12'>
                <div className='col center'>
                {items.map((item, index) => (
                    <SlideInElementBottom key={index} > 
                    <div style={{
                        background: index % 2 === 1 ? getLinearBackground() : `${seccionesData.Config.mode==="light"? "#f8f8f8":"#101010"}`,
                    }}>
                        {React.cloneElement(item, { preview: preview, color: seccionesData.Config.color, linearBg: index % 2 === 1 ? getLinearBackground() : `${seccionesData.Config.mode==="light"? "#f8f8f8":"#101010"}` })}
                    </div>
                    </SlideInElementBottom>
                ))}
                </div>
            </div>
        </div>
    )
}

export default CircleVibeProd;