import './../Icon.css';
const AboutMeIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path d="M23.6517 25C28.6223 25 32.6517 20.9706 32.6517 16C32.6517 11.0294 28.6223 7 23.6517 7C18.6811 7 14.6517 11.0294 14.6517 16C14.6517 20.9706 18.6811 25 23.6517 25Z" />
            <path d="M16.5058 26.5C14.8013 26.5 13.2431 27.463 12.4809 28.9875L9.48088 34.9875C7.98486 37.9796 10.1606 41.5 13.5058 41.5H33.7976C37.1428 41.5 39.3186 37.9796 37.8225 34.9875L34.8225 28.9875C34.0603 27.463 32.5021 26.5 30.7976 26.5H16.5058Z"/>
        </svg>
    )
}

export default AboutMeIcon;



