import React, { useContext } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';

const Menu = (props)=>{
    const { seccionesData } = useContext(InputValuesContext);
    const { click , optionSelected} = props;
    const menuStyle = {
        display: 'flex',
        width: '240px',
        padding: '0px 32px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '16px',
        position: 'absolute',
        left: '0px',
        top: '196px'
      };

    return (
        <div style={menuStyle}>
            <div className='cta'>Menu</div>
            <ul style={{margin:'0px' , gap:'9px'}}>
                <li className='body clickeable' onClick={()=>click(-1)}
                    style={{color: optionSelected===-1?seccionesData.Config.color:''}}
                >All</li>
                {seccionesData.Galeria.galerias.map((item, index) => (
                    <li key={index} className='body clickeable' onClick={()=>click(index)}
                        style={{color: optionSelected===index.toString()?seccionesData.Config.color:''}}
                    >{item.titulo}</li>
                ))}
            </ul>
        </div>
    )
}

export default Menu;    