import React from 'react';
import './Tab.css';

const Tab = (props)=>{

    const {items, itemSelected, click} = props;

    const handleClick=(value)=>{
        click(value);
    }

    return (
        <div className='tabContainer'>
            {items.map(item => (
               <div className={`tabItem ${itemSelected===item?"tabItemSelected":""} clickeable`} onClick={()=>handleClick(item)}>
                    <p className='subtitle_1'>{item}</p>
               </div>
            ))}
        </div>
    )
}

export default Tab;