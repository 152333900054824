import './../Icon.css';
const GaleryIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path fillRule="evenodd" clipRule="evenodd" d="M6 10C6 9.44772 6.44772 9 7 9H41C41.5523 9 42 9.44772 42 10V38C42 38.5523 41.5523 39 41 39H7C6.44772 39 6 38.5523 6 38V10ZM35.0979 33H12.809C12.4373 33 12.1956 32.6088 12.3618 32.2764L17.679 21.6421C17.8346 21.3307 18.2529 21.2697 18.491 21.5237L26.6352 30.2109C26.8328 30.4216 27.1672 30.4216 27.3648 30.2109L31.0556 26.274C31.2801 26.0346 31.6704 26.0727 31.8444 26.351L35.5219 32.235C35.73 32.568 35.4906 33 35.0979 33ZM30 21C31.6569 21 33 19.6569 33 18C33 16.3431 31.6569 15 30 15C28.3431 15 27 16.3431 27 18C27 19.6569 28.3431 21 30 21Z"/>
        </svg>
    )
}

export default GaleryIcon;



