import React, { useContext } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';

const SubProyecto = (props)=>{

    const { seccionesData } = useContext(InputValuesContext);

    const { type, subProyectoIndex} = props;

    return (
        <div id="CardiLuxProyecto" className={`specialSectionContainer secondaryColor type${type}`}  style={{ minHeight : "calc(100vh - 204px)"}}>
            <div className={`specialSectionContent type${type}`} >
                <div className={`informationContainer type${type}`} >
                    <h2 className={`headingP_2 type${type} primaryColor ${seccionesData.Config.changing==="proyectos.titulo"?"frameOver":""}`}>
                        {seccionesData.Config.changing==="proyectos.titulo" &&
                            <div className='frameOverDetail'>
                                <span className='caption secondaryColor'>Titulo</span>
                            </div>
                        }
                        {seccionesData.Proyectos.proyectos[subProyectoIndex].titulo}
                    </h2>
                    <div className='dateRol'>
                        <span className={`captionP type${type} textColor`}>{seccionesData.Proyectos.proyectos[subProyectoIndex].mes} - {seccionesData.Proyectos.proyectos[subProyectoIndex].anio}</span>
                        <span className={`captionP type${type} textColor`}>•</span>
                        <span className={`captionP type${type} textColor ${seccionesData.Config.changing==="proyectos.rol"?"frameOver":""}`}>
                            {seccionesData.Config.changing==="proyectos.rol" &&
                                <div className='frameOverDetail'>
                                    <span className='caption secondaryColor'>Rol</span>
                                </div>
                            }
                            {seccionesData.Proyectos.proyectos[subProyectoIndex].rol}
                        </span>
                    </div>
                    <p className={`bodyP_2 type${type} textColor ${seccionesData.Config.changing==="proyectos.descripcion"?"frameOver":""}`}>
                        {seccionesData.Config.changing==="proyectos.descripcion" &&
                            <div className='frameOverDetail'>
                                <span className='caption secondaryColor'>Descripcion</span>
                            </div>
                        }
                        {seccionesData.Proyectos.proyectos[subProyectoIndex].descripcion}
                    </p>
                    {seccionesData.Proyectos.proyectos[subProyectoIndex].imagen!=="" && (
                        <img style={{ maxWidth : '100%' , maxHeight : '100%', objectFit:"contain"}} src={seccionesData.Proyectos.proyectos[subProyectoIndex].imagen} alt=" "/>
                    )}
                </div>
                {seccionesData.Proyectos.proyectos[subProyectoIndex].bloques.filter(element=>element.active).map((item,index)=>(
                    <div key={index} className={`informationContainer type${type}`}>
                        <h3 className={`headingP_3 type${type} primaryColor`}>{item.titulo}</h3>
                        {item.content.map((subitem,subindex)=>{
                            return (
                                <React.Fragment key={subindex}>
                                    {subitem.key === "Subtitulo" &&
                                        <h4 className={`headingP_4 type${type} primaryColor`}>{subitem.content}</h4>
                                    }
                                    {subitem.key === "Texto" &&
                                        <p className={`bodyP_2 type${type} textColor`}>{subitem.content}</p>
                                    }
                                    {subitem.key === "Lista" &&
                                        <div>
                                            {subitem.content.split('\n').map((line, subitemindex) => (
                                                <p key={subitemindex} className={`bodyP_2 type${type} textColor`}>
                                                    <span>{subitemindex + 1}: </span>
                                                    {line}
                                                </p>
                                            ))}
                                        </div>
                                    }
                                    {subitem.key === "Imagen" &&
                                        <img style={{ maxWidth : '100%' , maxHeight : '100%', objectFit:"contain"}} src={item.content} alt=" "/>
                                    }
                                </React.Fragment>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SubProyecto;