import React from 'react';
import './BuilderTopBar.css';
import Button from '../../Button/Button';
import ProfolioIcon from '../../../util/constants/icons/ProfolioIcon';

const BuilderTopBar =(props)=>{

    const {buttonCallback, previewCallback, preview} = props;
    
    return (
        <div className='builderTopBarContainer'>
            <div className='builderTopBarName'>
                <div className='builderTopBarLogo'>
                    <ProfolioIcon></ProfolioIcon>
                </div>
                <div className='builderTopBarTag'>
                    <div className='builderTopBarTagText'>Premiun</div>
                </div>
            </div>
            <div className='row gap16'>
                <div className='col center'>
                    <div className='row center'>
                        {preview==="Mobile"?( 
                            <div className='menuCardActive center clickeable'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='custom-fill3'>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 4.25C6 3.00736 7.00736 2 8.25 2H15.75C16.9926 2 18 3.00736 18 4.25V19.25C18 20.4926 16.9926 21.5 15.75 21.5H8.25C7.00736 21.5 6 20.4926 6 19.25V4.25ZM8.25 3.5C7.83579 3.5 7.5 3.83579 7.5 4.25V19.25C7.5 19.6642 7.83579 20 8.25 20H15.75C16.1642 20 16.5 19.6642 16.5 19.25V4.25C16.5 3.83579 16.1642 3.5 15.75 3.5H8.25ZM9.75 5C9.75 4.58579 10.0858 4.25 10.5 4.25H13.5C13.9142 4.25 14.25 4.58579 14.25 5C14.25 5.41421 13.9142 5.75 13.5 5.75H10.5C10.0858 5.75 9.75 5.41421 9.75 5Z"/>
                                </svg>
                            </div>
                        ):(
                            <div className='menuCard center clickeable' onClick={()=>previewCallback("Mobile")}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='custom-fill2'>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 4.25C6 3.00736 7.00736 2 8.25 2H15.75C16.9926 2 18 3.00736 18 4.25V19.25C18 20.4926 16.9926 21.5 15.75 21.5H8.25C7.00736 21.5 6 20.4926 6 19.25V4.25ZM8.25 3.5C7.83579 3.5 7.5 3.83579 7.5 4.25V19.25C7.5 19.6642 7.83579 20 8.25 20H15.75C16.1642 20 16.5 19.6642 16.5 19.25V4.25C16.5 3.83579 16.1642 3.5 15.75 3.5H8.25ZM9.75 5C9.75 4.58579 10.0858 4.25 10.5 4.25H13.5C13.9142 4.25 14.25 4.58579 14.25 5C14.25 5.41421 13.9142 5.75 13.5 5.75H10.5C10.0858 5.75 9.75 5.41421 9.75 5Z"/>
                                </svg>
                            </div>
                        )}
                        {preview==="Computer"?( 
                            <div className='menuCardActive center clickeable'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='custom-fill3'>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.5 5.25C4.08579 5.25 3.75 5.58579 3.75 6V15C3.75 15.4142 4.08579 15.75 4.5 15.75H19.5C19.9142 15.75 20.25 15.4142 20.25 15V6C20.25 5.58579 19.9142 5.25 19.5 5.25H4.5ZM12.75 17.25H19.5C20.7426 17.25 21.75 16.2426 21.75 15V6C21.75 4.75736 20.7426 3.75 19.5 3.75H4.5C3.25736 3.75 2.25 4.75736 2.25 6V15C2.25 16.2426 3.25736 17.25 4.5 17.25H11.25V18.75H7.5C7.08579 18.75 6.75 19.0858 6.75 19.5C6.75 19.9142 7.08579 20.25 7.5 20.25H16.5C16.9142 20.25 17.25 19.9142 17.25 19.5C17.25 19.0858 16.9142 18.75 16.5 18.75H12.75V17.25Z"/>
                                </svg>
                            </div>
                        ):(
                            <div className='menuCard center clickeable' onClick={()=>previewCallback("Computer")}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='custom-fill2'>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.5 5.25C4.08579 5.25 3.75 5.58579 3.75 6V15C3.75 15.4142 4.08579 15.75 4.5 15.75H19.5C19.9142 15.75 20.25 15.4142 20.25 15V6C20.25 5.58579 19.9142 5.25 19.5 5.25H4.5ZM12.75 17.25H19.5C20.7426 17.25 21.75 16.2426 21.75 15V6C21.75 4.75736 20.7426 3.75 19.5 3.75H4.5C3.25736 3.75 2.25 4.75736 2.25 6V15C2.25 16.2426 3.25736 17.25 4.5 17.25H11.25V18.75H7.5C7.08579 18.75 6.75 19.0858 6.75 19.5C6.75 19.9142 7.08579 20.25 7.5 20.25H16.5C16.9142 20.25 17.25 19.9142 17.25 19.5C17.25 19.0858 16.9142 18.75 16.5 18.75H12.75V17.25Z"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
                <Button texto={"Finalizar"} tipo={1} size={"s"} click={buttonCallback}></Button>
            </div>
        </div>
    )
}

export default BuilderTopBar;
