import React from 'react';

const Droppable = ({ children }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    e.target.classList.add('drag-over');
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove('drag-over');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.target.classList.remove('drag-over');
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className="droppable gap8 col" 
    >
      {children}
    </div>
  );
};

export default Droppable;
