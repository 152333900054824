import React from 'react';
import logoBlack from '../../../assets/icons/profolio-logotipoBlack.svg';
import logo from '../../../assets/icons/profolio-logotipo.svg';
import { useNavigate   } from 'react-router-dom';

const TopBar = (props)=>{
    const {color , logoString , mode} = props;
    const navigate  = useNavigate ();

    return (
        <div className='topBarCircleVibe' style={{ background:color}}>
            {logoString===''? (
            <div>
                {mode === 'dark' ? (
                <img src={logo} alt='' className='logo'></img>
                ) : (
                <img src={logoBlack} alt='' className='logo'></img>
                )}
            </div>
            ):(
            <img src={logoString} alt='' className='logo'></img>
            )}
            
            <div className='topBarOptionsCircleVibe'>
                <div className='topBarOptionsTextCircleVibe' onClick={()=>navigate('/')}>Inicio</div>
                <div className='topBarOptionsTextCircleVibe' onClick={()=>navigate('/show/galeria/all')}>Galeria</div>
                <div className='topBarOptionsTextCircleVibe' onClick={()=>navigate('/show/proyecto/all')}>Proyectos</div>
                <div className='topBarOptionsTextCircleVibe' onClick={()=>navigate('/show/casoestudio/all')}>Casos</div>
                <div className='topBarOptionsTextCircleVibe' onClick={()=>navigate('/show/articulo/all')}>Articulo</div>
            </div>
        </div>
    )
}

export default TopBar;