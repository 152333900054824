import React, { useContext } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';

const SubArticulo = (props)=>{
    const { seccionesData } = useContext(InputValuesContext);
    const { preview,color,subArticuloIndex} = props;

    return (
        <div id="CircleVibeSubArticulo" className={`generalContainer${preview ? preview : 'Prod'} gap32`}  style={{ minHeight : "calc(100vh - 204px)"}}>
            <div className={`col-m12${preview ? preview : 'Prod'} gap12`}>
                <div className='titleLarge' style={{color: color}}>{seccionesData.Articulo.articulos[subArticuloIndex].titulo}</div>
                <div className='body'>{seccionesData.Articulo.articulos[subArticuloIndex].descripcion}</div>
                {seccionesData.Articulo.articulos[subArticuloIndex].imagen!=="" && (
                    <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={seccionesData.Articulo.articulos[subArticuloIndex].imagen} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                )}
            </div>
            <div className={`col-m12${preview ? preview : 'Prod'} gap12`}>
                {seccionesData.Articulo.articulos[subArticuloIndex].bloques.map((item,index)=>(
                    <div key={index}>
                        <div className='title' style={{color: color}}>{item.titulo.value}</div>
                        <div className='subtitle' style={{color: color}}>{item.subtitulo.value}</div>
                        <div className='body'>{item.descripcion.value}</div>
                        {item.imagen.value!=="" && (
                            <img style={{ maxWidth : '100%' , maxHeight : '100%'}} src={item.imagen.value} alt="Description of the image" className='galeriaGeneralImageCircleVibe'/>
                        )}
                        {item.lista.value!=="" && 
                            <ul>
                                {item.lista.value.split('\n').map((subitem, index) => (
                                    <li key={index} className='cta'>{subitem}</li>
                                ))}
                            </ul>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}


export default SubArticulo;