import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import Navigation from './Navigation';
import Home from './Home';
import Pricing from './Pricing';
import Portfolios from './Portfolios';
import Footer from './Footer';
import Login from './Login';
import Landing from './Landing/Landing';


const HomeRouter = () => {

    const [showLogin, setShowLogin] = useState(false);
    const [showHome, setShowHome] = useState(false);
    const [loginStage, setLoginStage] = useState(0);

    const [portafolioComponent, setPortafolioComponent] = useState({component : null});

    useEffect(() => {
        const getPortafolio = async () => {
            const { default: NewComponent } = await import(`./PoolFormularios/CircleVibe/CircleVibeProd`);
            setPortafolioComponent({ component: <NewComponent/> });
        };

        const subdomain = window.location.hostname.split('.')[0];
        if(subdomain !== "profolio-dev" && subdomain!=='localhost'  && subdomain!=='profolioapp'){
            // TODO call database y actualizar seccionesData, segun subdomain
            
            getPortafolio();
            /*fetch('https://5v1iqo7cn1.execute-api.us-east-1.amazonaws.com/prod/data?username=jaragon', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json', // Set the appropriate content type
                },
                }).then((response) => console.log(response)) */
            setShowHome(false);
        }else{
            setShowHome(true);
        }
    }, []);

    /*
    const changeShowLogin = (stage)=>{
        if(showLogin)setLoginStage(0);
        else setLoginStage(stage);
        setShowLogin(!showLogin);
    }
    */

    return (
      <div>
        {showHome?(
            <div id="homeRouter" className='col center'>
                <Navigation/>
                <Landing></Landing>
                {/*
                <Element name="home" className="section2">
                    <Home></Home>
                </Element>
                <Element name="portfolios" className="section1">
                    <Portfolios />
                </Element>
                <Element name="pricing" className="section2">
                    <Pricing />
                </Element>
                <Element name="footer" className="section1" style={{ marginBottom: '-50px' }}>
                    <Footer />
                </Element>
                */}
            </div>
        ):(
            <div>
                {portafolioComponent.component && 
                    <div>
                        {React.cloneElement(portafolioComponent.component) }
                    </div>
                }
            </div>
        )}
        
        
      </div>
    );
  };
  
  export default HomeRouter;