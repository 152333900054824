import React from 'react';
import './CardiLux.css';
import ProfolioIcon from '../../../util/constants/icons/ProfolioIcon';

const TopBarCardiLux =(props)=>{

    const {type} = props;


    return (
        <div className={`topBarContainerCardiLux type${type}`}>
            <div className='logoContainer'>
                <ProfolioIcon></ProfolioIcon>
            </div>
        </div>
    )
}

export default TopBarCardiLux;