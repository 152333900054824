import React from "react";
import { useNavigate} from 'react-router-dom';
import p1D from '../assets/img/p1D.png';
import p1M from '../assets/img/p1M.png';
import p2D from '../assets/img/p2D.png';
import p2M from '../assets/img/p2M.png';
import p3D from '../assets/img/p3D.png';
import p3M from '../assets/img/p3M.png';
import p4D from '../assets/img/p4D.png';
import p4M from '../assets/img/p4M.png';
import p5D from '../assets/img/p5D.png';
import p5M from '../assets/img/p5M.png';
import p6D from '../assets/img/p6D.png';
import p6M from '../assets/img/p6M.png';
import Button from "../general-components/Button/Button";
import ProfolioIcon from './../util/constants/icons/ProfolioIcon';

const Portafolios = ()=>{

    const navigate = useNavigate ();

    const handlePortafolio= (whereTo)=>{
        navigate("/formulario", { state: { portafolio: whereTo, inf : false } });
    }

    return (
        <div className='col center scrollable' >
            <div className='top-bar'>
                <ProfolioIcon click={()=>navigate("/")}></ProfolioIcon>
                <Button texto={"Regresar"} tipo={1} size={"s"} click={()=>navigate("/")}></Button>
            </div>
               <div className="templatesContainer">
                    <div className="col gap16">
                        <h3 className="heading_3">Selecciona una de las plantillas</h3>
                        <p className="body_l">Elige entre nuestras increíbles plantillas diseñadas.</p>
                    </div>
                    <div className="templateContainer">
                        <div className="portfolioTemplateItem">
                            <div className='portfolioImageContainer'>
                                <div className={`portfolioImage1`} style={{backgroundImage:  `url(${p1D})`}}></div>
                                <div className={`portfolioImage2`} style={{backgroundImage:  `url(${p1M})`}}></div>
                            </div>
                            <div className="col-m12 gap8">
                                <Button texto={"Seleccionar"} tipo={1} size={"s"} fill={true} click={()=>handlePortafolio("ModernVibe")}></Button>
                                <Button texto={"Vista Previa"} tipo={2} size={"s"} click={()=>window.open("/portafolios/ModernVibe", '_blank')}></Button>
                            </div>
                        </div>
                        <div className="portfolioTemplateItem">
                            <div className='portfolioImageContainer'>
                                <div className={`portfolioImage1`} style={{backgroundImage:  `url(${p2D})`}}></div>
                                <div className={`portfolioImage2`} style={{backgroundImage:  `url(${p2M})`}}></div>
                            </div>
                            <div className="col-m12 gap8">
                                <Button texto={"Seleccionar"} tipo={1} size={"s"} fill={true} click={()=>handlePortafolio("CardiLux")}></Button>
                                <Button texto={"Vista Previa"} tipo={2} size={"s"} click={()=>window.open("/portafolios/CardiLux", '_blank')}></Button>
                            </div>
                        </div>
                        <div className="portfolioTemplateItem">
                            <div className='portfolioImageContainer'>
                                <div className={`portfolioImage1`} style={{backgroundImage:  `url(${p3D})`}}></div>
                                <div className={`portfolioImage2`} style={{backgroundImage:  `url(${p3M})`}}></div>
                            </div>
                            <div className="col-m12 gap8 center" style={{height:"90px"}}>
                                <Button texto={"Proximamente"} tipo={2} size={"s"} fill={true} disabled={true} click={()=>null}></Button>
                            </div>
                        </div>
                    </div>
                    <div className="templateContainer">
                        <div className="portfolioTemplateItem">
                            <div className='portfolioImageContainer'>
                                <div className={`portfolioImage1`} style={{backgroundImage:  `url(${p4D})`}}></div>
                                <div className={`portfolioImage2`} style={{backgroundImage:  `url(${p4M})`}}></div>
                            </div>
                            <div className="col-m12 gap8 center" style={{height:"90px"}}>
                                <Button texto={"Proximamente"} tipo={2} size={"s"} fill={true} disabled={true} click={()=>null}></Button>
                            </div>
                        </div>
                        <div className="portfolioTemplateItem">
                            <div className='portfolioImageContainer'>
                                <div className={`portfolioImage1`} style={{backgroundImage:  `url(${p5D})`}}></div>
                                <div className={`portfolioImage2`} style={{backgroundImage:  `url(${p5M})`}}></div>
                            </div>
                            <div className="col-m12 gap8 center" style={{height:"90px"}}>
                                <Button texto={"Proximamente"} tipo={2} size={"s"} fill={true} disabled={true} click={()=>null}></Button>
                            </div>
                        </div>
                        <div className="portfolioTemplateItem">
                            <div className='portfolioImageContainer'>
                                <div className={`portfolioImage1`} style={{backgroundImage:  `url(${p6D})`}}></div>
                                <div className={`portfolioImage2`} style={{backgroundImage:  `url(${p6M})`}}></div>
                            </div>
                            <div className="col-m12 gap8 center" style={{height:"90px"}}>
                                <Button texto={"Proximamente"} tipo={2} size={"s"} fill={true} disabled={true} click={()=>null}></Button>
                            </div>
                        </div>
                    </div> 
                </div> 
        </div>
    )
}

export default Portafolios;