import './../Icon.css';
const CaseIcon = (props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        e.stopPropagation();
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path fillRule="evenodd" clipRule="evenodd" d="M22 12.4C22 12.7314 22.2686 13 22.6 13H29.5515C30.086 13 30.3537 12.3537 29.9757 11.9757L23.0243 5.02426C22.6463 4.64629 22 4.91399 22 5.44853V12.4Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M37 32.5C37 34.9853 34.9853 37 32.5 37C30.0147 37 28 34.9853 28 32.5C28 30.0147 30.0147 28 32.5 28C34.9853 28 37 30.0147 37 32.5ZM36.6361 38.7574C35.4504 39.5427 34.0286 40 32.5 40C28.3579 40 25 36.6421 25 32.5C25 28.3579 28.3579 25 32.5 25C36.6421 25 40 28.3579 40 32.5C40 34.0286 39.5427 35.4504 38.7574 36.6361L42.5607 40.4393C43.1464 41.0251 43.1464 41.9749 42.5607 42.5607C41.9749 43.1464 41.0251 43.1464 40.4393 42.5607L36.6361 38.7574Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.5 4.6V39.4C5.5 39.7314 5.76863 40 6.1 40H27.5233C25.0983 38.3876 23.5 35.6305 23.5 32.5C23.5 28.0405 26.7434 24.3386 31 23.6244V15.1C31 14.7686 30.7314 14.5 30.4 14.5H20.3059C19.9745 14.5 19.7059 14.2314 19.7059 13.9V4.6C19.7059 4.26863 19.4373 4 19.1059 4H6.1C5.76863 4 5.5 4.26863 5.5 4.6ZM10 17.5C10 16.6716 10.6105 16 11.3636 16H16.8182C17.5713 16 18.1818 16.6716 18.1818 17.5C18.1818 18.3284 17.5713 19 16.8182 19H11.3636C10.6105 19 10 18.3284 10 17.5ZM10 23.5C10 22.6716 10.6105 22 11.3636 22H23.6364C24.3895 22 25 22.6716 25 23.5C25 24.3284 24.3895 25 23.6364 25H11.3636C10.6105 25 10 24.3284 10 23.5ZM10 29.5C10 28.6716 10.6105 28 11.3636 28H19.5455C20.2986 28 20.9091 28.6716 20.9091 29.5C20.9091 30.3284 20.2986 31 19.5455 31H11.3636C10.6105 31 10 30.3284 10 29.5Z"/>
        </svg>
    )
}

export default CaseIcon;



