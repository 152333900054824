import React from 'react';
import './Custom.css';
import ProfolioIcon from '../../../util/constants/icons/ProfolioIcon';

const TopBarModernVibe =(props)=>{

    const {type} = props;


    return (
        <div className={`topBarContainerModernVibe type${type}`}>
            <div className='logoContainer'>
                <ProfolioIcon></ProfolioIcon>
            </div>
            <div className='logoContainer'></div>
        </div>
    )
}

export default TopBarModernVibe;