import './../Icon.css';
const SkillsIcon =(props)=>{

    const {click, size} = props;

    const handleClick =(e)=>{
        if(click)click(e);
    }
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path d="M7.91948 2.13651C7.98041 1.9545 8.23785 1.9545 8.29879 2.13651L9.59787 6.01707C9.62517 6.09861 9.70154 6.15358 9.78753 6.15358H13.9785C14.1737 6.15358 14.2532 6.40454 14.0937 6.51702L10.7136 8.90061C10.6414 8.95152 10.6111 9.04377 10.6392 9.12755L11.9327 12.9915C11.994 13.1747 11.7857 13.3297 11.6278 13.2184L8.22439 10.8184C8.15528 10.7697 8.06298 10.7697 7.99387 10.8184L4.5905 13.2184C4.4326 13.3297 4.22425 13.1747 4.28558 12.9915L5.5791 9.12755C5.60714 9.04377 5.5769 8.95152 5.5047 8.90061L2.12454 6.51703C1.96502 6.40454 2.04461 6.15358 2.2398 6.15358H6.43073C6.51672 6.15358 6.59309 6.09861 6.62039 6.01707L7.91948 2.13651Z" />
            <path d="M13.418 8.18835C13.4761 8.00041 13.7421 8.00041 13.8002 8.18835L14.0377 8.9569C14.0636 9.0407 14.1411 9.09785 14.2288 9.09785H15.0179C15.2092 9.09785 15.2913 9.34064 15.1393 9.45678L14.4839 9.95743C14.4173 10.0083 14.3895 10.0953 14.4143 10.1754L14.6605 10.9721C14.7179 11.1582 14.5027 11.3083 14.348 11.1901L13.7305 10.7185C13.6589 10.6637 13.5594 10.6637 13.4877 10.7185L12.8703 11.1901C12.7156 11.3083 12.5003 11.1582 12.5578 10.9721L12.804 10.1754C12.8288 10.0953 12.8009 10.0083 12.7343 9.95743L12.079 9.45678C11.927 9.34064 12.0091 9.09785 12.2004 9.09785H12.9895C13.0772 9.09785 13.1547 9.0407 13.1806 8.9569L13.418 8.18835Z" />
            <path d="M2.41805 9.18835C2.47612 9.00041 2.74214 9.00041 2.80022 9.18835L3.03771 9.9569C3.06361 10.0407 3.14108 10.0978 3.2288 10.0978H4.01787C4.20918 10.0978 4.29131 10.3406 4.13928 10.4568L3.48392 10.9574C3.41732 11.0083 3.38951 11.0953 3.41425 11.1754L3.66046 11.9721C3.71795 12.1582 3.5027 12.3083 3.34796 12.1901L2.73054 11.7185C2.65886 11.6637 2.5594 11.6637 2.48772 11.7185L1.8703 12.1901C1.71556 12.3083 1.50031 12.1582 1.5578 11.9721L1.80401 11.1754C1.82876 11.0953 1.80094 11.0083 1.73434 10.9574L1.07898 10.4568C0.926955 10.3406 1.00908 10.0978 1.20039 10.0978H1.98947C2.07718 10.0978 2.15466 10.0407 2.18055 9.9569L2.41805 9.18835Z" />
        </svg>
    )
}

export default SkillsIcon;