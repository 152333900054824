import './Icon.css';
const TypographyIcon = (props)=>{

    const {click, size, style} = props;

    const handleClick =(e)=>{
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={`btnIcon ${click?"clickeable":""}  ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""} ${style!==undefined?style:""}`} onClick={(e)=>handleClick(e)}>
            <path d="M11.5 4H4.5V6H7V10.5H6V12H10V10.5H9V6H11.5V4Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12 1H15V4H14V12H15V15H12V14H4V15H1V12H2V4H1V1H4V2H12V1ZM4 13H12V12H13V4H12V3H4V4H3V12H4V13ZM13 2V3H14V2H13ZM13 13V14H14V13H13ZM2 14V13H3V14H2ZM2 2V3H3V2H2Z" />
        </svg>
    )
}

export default TypographyIcon;

