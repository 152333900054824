import React, { useContext } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';

const SubArticulo = (props)=>{

    const { seccionesData } = useContext(InputValuesContext);
    
    const { type ,subArticuloIndex} = props;

    return (
        <div id="CardiLuxSubArticulo" className={`specialSectionContainer secondaryColor type${type}`}  style={{ minHeight : "calc(100vh - 204px)"}}>
            <div className={`specialSectionContent type${type}`} >
                <div className={`informationContainer type${type}`} >
                    <h2 className={`headingP_2 type${type} primaryColor ${seccionesData.Config.changing==="articulos.titulo"?"frameOver":""}`}>
                        {seccionesData.Config.changing==="articulos.titulo" &&
                            <div className='frameOverDetail'>
                                <span className='caption secondaryColor'>Titulo</span>
                            </div>
                        }
                        {seccionesData.Articulo.articulos[subArticuloIndex].titulo}
                    </h2>
                    <span className={`captionP type${type} textColor`}>{seccionesData.Articulo.articulos[subArticuloIndex].dia}/{seccionesData.Articulo.articulos[subArticuloIndex].mes}/{seccionesData.Articulo.articulos[subArticuloIndex].anio}</span>                  
                    <p className={`bodyP_2 type${type} textColorr ${seccionesData.Config.changing==="articulos.descripcion"?"frameOver":""}`}>
                        {seccionesData.Config.changing==="articulos.descripcion" &&
                            <div className='frameOverDetail'>
                                <span className='caption secondaryColor'>Descripcion</span>
                            </div>
                        }
                        {seccionesData.Articulo.articulos[subArticuloIndex].descripcion}
                    </p>
                    {seccionesData.Articulo.articulos[subArticuloIndex].imagen!=="" && (
                        <img style={{ maxWidth : '100%' , maxHeight : '100%', objectFit:"contain"}} src={seccionesData.Articulo.articulos[subArticuloIndex].imagen} alt=" "/>
                    )}
                </div>
                <div className={`informationContainer type${type}`}>
                    {seccionesData.Articulo.articulos[subArticuloIndex].content.map((item,index)=>{
                                return (
                                    <React.Fragment key={index}>
                                        {item.key === "Subtitulo" &&
                                            <h4 className={`headingP_4 type${type} primaryColor`}>{item.content}</h4>
                                        }
                                        {item.key === "Texto" &&
                                            <p className={`bodyP_2 type${type} textColor`}>{item.content}</p>
                                        }
                                        {item.key === "Lista" &&
                                            <div>
                                                {item.content.split('\n').map((line, subitemindex) => (
                                                    <p key={subitemindex} className={`bodyP_2 type${type} textColor`}>
                                                        <span>{subitemindex + 1}: </span>
                                                        {line}
                                                    </p>
                                                ))}
                                        </div>
                                        }
                                        {item.key === "Imagen" &&
                                            <img style={{ maxWidth : '100%' , maxHeight : '100%', objectFit:"contain"}} src={item.content} alt=" "/>
                                        }
                                    </React.Fragment>
                                );
                    })}
                </div>
            </div>
        </div>
    )
}


export default SubArticulo;