import React from 'react';
import './../css/Pricing.css';
import CheckCircle from '@material-ui/icons/CheckCircle';
import SlideInElementBottom from '../util/SlideInElementBottom';
import Button from '../general-components/Button/Button';

const Pricing = () => {
  return (
    <div id="pricing" className='container-pricing'>
      <div className='col-m12 center gap32'>
          <div className='col center gap8'>
            <SlideInElementBottom center={true}>
              <div className='col-m12 center'>
                <h3 className='heading_3'>Nuestros Planes de Precios</h3>
              </div>
            </SlideInElementBottom>
            <SlideInElementBottom center={true}>
              <div className='col center' style={{width:"990px"}}>
                <p className='body_l centerText'>Ofrecemos opciones flexibles y adaptadas a tus necesidades. Elige el plan que mejor se ajuste a tu perfil y comienza a crear un portafolio impactante</p>
              </div>
            </SlideInElementBottom>
          </div>
          <SlideInElementBottom center={true}>
            <div className='row card-container'>
              <div className='card-container1'>
                <div className='col gap4'>
                  <h4 className='heading_4'>Plan básico</h4>
                  <h5 className='heading_5'>$9.90</h5>
                  <span className='caption'>Mensual</span>
                </div>
                <div className='col gap8'>
                  <div className='row left'>
                    <CheckCircle className='checkCircle1'></CheckCircle>
                    <p className='body_m'>Plantillas básicas</p>
                  </div>
                  <div className='row left'>
                    <CheckCircle className='checkCircle1'></CheckCircle>
                    <p className='body_m'>4 secciones máximo</p>
                  </div>
                  <div className='row left'>
                    <CheckCircle className='checkCircle1'></CheckCircle>
                    <p className='body_m'>10 Mbs de almacenamiento</p>
                  </div>
                </div>
                <Button texto={"Empezar"} tipo={1} fill={true} click={()=>{}}></Button>
              </div>

              <div className='card-container2'>
                <div className='col gap4'>
                  <h4 className='heading_4'>Plan Profesional</h4>
                  <h5 className='heading_5'>$13.90</h5>
                  <span className='caption'>Mensual</span>
                </div>
                <div className='col gap8'>
                  <div className='row left'>
                    <CheckCircle className='checkCircle2'></CheckCircle>
                    <p className='body_m'>Acceso a plantillas Premium</p>
                  </div>
                  <div className='row left'>
                    <CheckCircle className='checkCircle2'></CheckCircle>
                    <p className='body_m'>Número de secciones ilimitadas</p>
                  </div>
                  <div className='row left'>
                    <CheckCircle className='checkCircle2'></CheckCircle>
                    <p className='body_m'>1 GB de almacenamiento</p>
                  </div>
                </div>
                <Button texto={"Empezar"} tipo={1} fill={true} click={()=>{}}></Button>
              </div>

              <div className='card-container1'>
                <div className='col gap4'>
                  <h4 className='heading_4'>Plan Premium</h4>
                  <h5 className='heading_5'>$49.90</h5>
                  <span className='caption'>Mensual</span>
                </div>
                <div className='col gap8'>
                  <div className='row left'>
                    <CheckCircle className='checkCircle1'></CheckCircle>
                    <p className='body_m'>Diseño y desarrollo personalizado</p>
                  </div>
                  <div className='row left'>
                    <CheckCircle className='checkCircle1'></CheckCircle>
                    <p className='body_m'>Soporte técnico prioritario</p>
                  </div>
                  <div className='row left'>
                    <CheckCircle className='checkCircle1'></CheckCircle>
                    <p className='body_m'>Dominio propio</p>
                  </div>
                </div>
                <Button texto={"Empezar"} tipo={1} fill={true} click={()=>{}}></Button>
              </div>
            </div>
          </SlideInElementBottom>
      </div>
    </div>
  );
};

export default Pricing;