import React, { useContext, useEffect, useState } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';
import SlideInElementBottom from '../../../util/SlideInElementBottom';

const SubGaleria =(props)=>{

    const { seccionesData } = useContext(InputValuesContext);

    const { type, subGaleriaIndex} = props;
    
    const [ imagenesWidth, setImagenesWidth] = useState('48%');
    const [ images, setImages] = useState({1:null,2:null,3:null,4:null});

    const adjustByNumberOfColumns = ()=>{
        setImages({1:null,2:null,3:null,4:null});
        
        let j = 0;
        let breaker1 = 0;
        let breaker2 = 0;
        let breaker3 = 0;
        let top = seccionesData.Galeria.galerias[subGaleriaIndex].imagenes.length;
        let i1 = [];
        let i2 = [];
        let i3 = [];
        let i4 = [];
        switch (seccionesData.Galeria.galerias[subGaleriaIndex].nColumnas){
            case "1":
                setImages(prevImages => ({...prevImages, [1]: seccionesData.Galeria.galerias[subGaleriaIndex].imagenes }));
                setImagenesWidth('98%')
                break;
            case "2":
                breaker1 = Math.ceil(top/2);
                while (j<breaker1){
                    i1.push(seccionesData.Galeria.galerias[subGaleriaIndex].imagenes[j]);
                    j+=1;
                }
                while (j<top){
                    i2.push(seccionesData.Galeria.galerias[subGaleriaIndex].imagenes[j]);
                    j+=1;
                }
                setImages(prevImages => ({...prevImages, [1]: i1 }));
                setImages(prevImages => ({...prevImages, [2]: i2 }));
                setImagenesWidth('47%')
                break;
            case "3":
                breaker1 = Math.ceil(top/3);
                breaker2 = Math.ceil((top-breaker1)/2)+breaker1;
                while (j<breaker1){
                    i1.push(seccionesData.Galeria.galerias[subGaleriaIndex].imagenes[j]);
                    j+=1;
                }
                while (j<breaker2){
                    i2.push(seccionesData.Galeria.galerias[subGaleriaIndex].imagenes[j]);
                    j+=1;
                }
                while (j<top){
                    i3.push(seccionesData.Galeria.galerias[subGaleriaIndex].imagenes[j]);
                    j+=1;
                }
                setImages(prevImages => ({...prevImages, [1]: i1 }));
                setImages(prevImages => ({...prevImages, [2]: i2 }));
                setImages(prevImages => ({...prevImages, [3]: i3 }));
                setImagenesWidth('30%')
                break;
            case "4":
                breaker1 = Math.ceil(top/4);
                breaker2 = Math.ceil((top-breaker1)/3)+breaker1;
                breaker3 = Math.ceil((top-breaker2)/2)+breaker2;
                while (j<breaker1){
                    i1.push(seccionesData.Galeria.galerias[subGaleriaIndex].imagenes[j]);
                    j+=1;
                }
                while (j<breaker2){
                    i2.push(seccionesData.Galeria.galerias[subGaleriaIndex].imagenes[j]);
                    j+=1;
                }
                while (j<breaker3){
                    i3.push(seccionesData.Galeria.galerias[subGaleriaIndex].imagenes[j]);
                    j+=1;
                }
                while (j<top){
                    i4.push(seccionesData.Galeria.galerias[subGaleriaIndex].imagenes[j]);
                    j+=1;
                }
                setImages(prevImages => ({...prevImages, [1]: i1 }));
                setImages(prevImages => ({...prevImages, [2]: i2 }));
                setImages(prevImages => ({...prevImages, [3]: i3 }));
                setImages(prevImages => ({...prevImages, [4]: i4 }));
                setImagenesWidth('22%')
                break;
            default : break;
        }
    }

    useEffect(() => {
        adjustByNumberOfColumns();
    }, [seccionesData.Galeria.galerias[subGaleriaIndex].nColumnas]);

    useEffect(() => {
        adjustByNumberOfColumns();
    }, [seccionesData.Galeria.galerias[subGaleriaIndex].imagenes.length]);

    useEffect(() => {
        adjustByNumberOfColumns();
    }, []);

    return (
        <div id="ModernVibeSubGaleria" className={`generalContainer type${type}`} style={{ minHeight : "calc(100vh - 204px)"}}>
            <div className={`col-m12${type ? 'Preview' : 'Prod'} center`}>
                <SlideInElementBottom center={true}>
                    <div className={`col-m12${type ? 'Preview' : 'Prod'} center gap16`} style={{width:"600px"}}>
                        <h2 className={`headingP_2 type${type} primaryColor ${seccionesData.Config.changing==="galerias.titulo"?"frameOver":""}`}>
                            {seccionesData.Config.changing==="galerias.titulo" &&
                                <div className='frameOverDetail'>
                                    <span className='caption secondaryColor'>Titulo</span>
                                </div>
                            }
                            {seccionesData.Galeria.galerias[subGaleriaIndex].titulo}
                        </h2>
                        <p className={`bodyP_2 type${type} textColor ${seccionesData.Config.changing==="galerias.descripcion"?"frameOver":""}`}>
                            {seccionesData.Config.changing==="galerias.descripcion" &&
                                <div className='frameOverDetail'>
                                    <span className='caption secondaryColor'>Descripcion</span>
                                </div>
                            }
                            {seccionesData.Galeria.galerias[subGaleriaIndex].descripcion===''?seccionesData.Galeria.galerias[subGaleriaIndex].descripcionPreview:seccionesData.Galeria.galerias[subGaleriaIndex].descripcion}
                        </p>       
                    </div>  
                </SlideInElementBottom>  
                <div className={`row-m12${type ? type : 'Prod'} center gap32 wrap-row`} style={{alignItems:'flex-start'}}>
                    {images[1]!==null && (
                        <div  className={`col gap32`} style={{ width :imagenesWidth}}>
                            {images[1].map((item,subIndex)=>(
                                <SlideInElementBottom key={subIndex} center={true}>
                                    <div className={`center galeriaGeneralImageContainerCircleVibe`}>
                                    {item && (
                                        <img key={item} 
                                            style={{ maxWidth : '100%' , maxHeight : '100%'}} 
                                            src={`${item}?timestamp=${new Date().getTime()}`}
                                            alt="Description" 
                                            className='galeriaGeneralImageCircleVibe'/>
                                    )}
                                    </div>
                                </SlideInElementBottom>
                            ))}
                        </div>
                    )}
                    {images[2]!==null && (
                        <div  className={`col gap32`} style={{ width : imagenesWidth}}>
                            {images[2].map((item,subIndex)=>(
                                <SlideInElementBottom key={subIndex} center={true}>
                                    <div className={`center galeriaGeneralImageContainerCircleVibe`}>
                                    {item && (
                                        <img key={item} 
                                            style={{ maxWidth : '100%' , maxHeight : '100%'}} 
                                            src={`${item}?timestamp=${new Date().getTime()}`}
                                            alt="Description" 
                                            className='galeriaGeneralImageCircleVibe'/>
                                    )}
                                    </div>
                                </SlideInElementBottom>
                            ))}
                        </div>
                    )}
                    {images[3]!==null && (
                        <div  className={`col gap32`} style={{ width :imagenesWidth}}>
                            {images[3].map((item,subIndex)=>(
                                <SlideInElementBottom key={subIndex} center={true}>
                                    <div className={`center galeriaGeneralImageContainerCircleVibe`}>
                                    {item && (
                                        <img key={item} 
                                            style={{ maxWidth : '100%' , maxHeight : '100%'}} 
                                            src={`${item}?timestamp=${new Date().getTime()}`}
                                            alt="Description" 
                                            className='galeriaGeneralImageCircleVibe'/>
                                    )}
                                    </div>
                                </SlideInElementBottom>
                            ))}
                        </div>
                    )}
                    {images[4]!==null && (
                        <div  className={`col gap32`} style={{ width : imagenesWidth}}>
                            {images[4].map((item,subIndex)=>(
                                <SlideInElementBottom key={subIndex} center={true}>
                                    <div className={`center galeriaGeneralImageContainerCircleVibe`}>
                                    {item && (
                                        <img key={item} 
                                            style={{ maxWidth : '100%' , maxHeight : '100%'}} 
                                            src={`${item}?timestamp=${new Date().getTime()}`}
                                            alt="Description" 
                                            className='galeriaGeneralImageCircleVibe'/>
                                    )}
                                    </div>
                                </SlideInElementBottom>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SubGaleria;