import React from 'react';
import './RadioButton.css';

const RadioButton=(props)=>{

    const {click, selected, type, icon, children} = props;

    return (
        <div className={`radioButton${type?type:""} clickeable`} onClick={click}>
            <div className='row center'>
                {selected?(
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className='btnIcon'>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 10.5714C9.42016 10.5714 10.5714 9.42016 10.5714 8C10.5714 6.57984 9.42016 5.42857 8 5.42857C6.57984 5.42857 5.42857 6.57984 5.42857 8C5.42857 9.42016 6.57984 10.5714 8 10.5714ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"/>
                    </svg>
                ):(
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className='btnIcon'>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 12.7143C10.6036 12.7143 12.7143 10.6036 12.7143 8C12.7143 5.39637 10.6036 3.28571 8 3.28571C5.39637 3.28571 3.28571 5.39637 3.28571 8C3.28571 10.6036 5.39637 12.7143 8 12.7143ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"/>
                    </svg>
                )}
            </div>
            {React.Children.map(children, (child) =>
                React.cloneElement(child)
            )}
            {icon && <icon></icon>}
        </div>
    )
}

export default RadioButton;