import React, { useContext, useEffect, useState } from 'react';
import { InputValuesContext } from '../../../util/InputValuesContext';
import TopBarCardiLux from './TopBarCardiLux';
import SubGaleria from './SubGaleria';
import SubCaso from './SubCaso';
import SubProyecto from './SubProyecto';
import SubArticulo from './SubArticulo';
import './../VariablesBreakpoints.css';
import './../PortfoliosTypography.css';
import './Custom.css';
import './CardiLux.css';

const CardiLux=(props)=>{

    const { seccionesData } = useContext(InputValuesContext);
    
    const {inputs, scrollToElement,subGaleriaIndex, subCasoIndex, subProyectoIndex, subArticuloIndex, preview} = props;
    const [items, setItems] = useState([]);

    useEffect(() => {
      const targetElement = document.getElementById(`CardiLux${scrollToElement}`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' ,
        block: 'center',
        inline: 'center',});
      }
      if(preview==="Prod")updateItems();
    }, []);

    useEffect(() => {
    }, [items]);

    
    const updateItems = async () => {
      try {
        // TODO refactorizar Sobre mi para que sea estandarizado
        const importPromises = inputs.map((value) => import(`./${value==='Sobre mi'?'SobreMi':value}`));
        const importedComponents = await Promise.all(importPromises);
        let newItems = [];
        const updatedItems = newItems.concat(
            importedComponents.map(({ default: NewComponent }) => (
              <NewComponent preview={preview} key={Math.random()} />
            ))
          );
        setItems(updatedItems);
      } catch (error) {
        console.error('Error loading components:', error);
      }
    };

    useEffect(() => {
      document.documentElement.style.setProperty('--portfolio-font1', seccionesData.Config.tipografia.titulos===""?"Lobster":seccionesData.Config.tipografia.titulos);
      document.documentElement.style.setProperty('--portfolio-font2', seccionesData.Config.tipografia.cuerpo===""?"Poppins":seccionesData.Config.tipografia.cuerpo);
    }, [seccionesData.Config.tipografia]);

    useEffect(() => {
        document.documentElement.style.setProperty('--portfolio-color-primary', seccionesData.Config.color.primary===""?"#F7567C":seccionesData.Config.color.primary);
        document.documentElement.style.setProperty('--portfolio-color-secondary', seccionesData.Config.color.secondary===""?"#FCFCFC":seccionesData.Config.color.secondary);
        document.documentElement.style.setProperty('--portfolio-color-text', seccionesData.Config.color.textColor===""?"#430412":seccionesData.Config.color.textColor);
    }, [seccionesData.Config.color]);

    useEffect(() => {
      const targetElement = document.getElementById(`CardiLux${scrollToElement==="Sobre mi"?"SobreMi":scrollToElement}`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' ,
        block: 'center',
        inline: 'center',});
      }
    }, [scrollToElement]);

    useEffect(() => {
      updateItems();
    }, [inputs]);

    const getWidth=()=>{
      switch(preview){
        case "Computer":
          return "100%";
        case "Mobile":
          return "360px";
        default: return "100%";
      }
    }
    
    return (
      <div id="element" style={{ width : getWidth(), display:"flex", flexDirection:"column" , paddingRight:"0px"}} className='scrollable'>
            <TopBarCardiLux type={preview}></TopBarCardiLux>
            
            {subGaleriaIndex>=0 &&(
                <div  id="subgaleria">
                <SubGaleria key={subGaleriaIndex} subGaleriaIndex={subGaleriaIndex} type={preview}></SubGaleria>
                </div>
            )}
            {subCasoIndex>=0 &&(
                <div >
                <SubCaso key={subCasoIndex} subCasoIndex={subCasoIndex} type={preview} ></SubCaso>
                </div>
            )}
            {subProyectoIndex>=0 &&(
                <div>
                <SubProyecto key={subProyectoIndex} subProyectoIndex={subProyectoIndex} type={preview} ></SubProyecto>
                </div>
            )}
            {subArticuloIndex>=0 &&(
                <div >
                <SubArticulo key={subArticuloIndex} subArticuloIndex={subArticuloIndex} type={preview}></SubArticulo>
                </div>
            )}
            {(subGaleriaIndex<0 && subCasoIndex<0 && subProyectoIndex<0 && subArticuloIndex<0) &&(
                <div className='col-m12 '>
                    <div>
                        {items.map((item, index) => (
                        <div key={index} style={{position:"relative"}}>
                            {React.cloneElement(item, { type: preview})}
                        </div>
                        ))}
                    </div>
                </div>
            )}
      </div>
    )
}

export default CardiLux;