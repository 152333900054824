import './Icon.css';

const Dots3Icon = (props)=>{

    const {click, size, style} = props;

    const handleClick =(e)=>{
        if(click)click(e);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" 
            className={`btnIcon ${click?"clickeable":""} ${size==='s'?"btnIcon_s":""} ${size==='m'?"btnIcon_m":""}`} onClick={(e)=>handleClick(e)}>
            <path d="M9.5 4C9.5 4.82843 8.82843 5.5 8 5.5C7.17157 5.5 6.5 4.82843 6.5 4C6.5 3.17157 7.17157 2.5 8 2.5C8.82843 2.5 9.5 3.17157 9.5 4Z" />
            <path d="M9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8Z" />
            <path d="M9.5 12C9.5 12.8284 8.82843 13.5 8 13.5C7.17157 13.5 6.5 12.8284 6.5 12C6.5 11.1716 7.17157 10.5 8 10.5C8.82843 10.5 9.5 11.1716 9.5 12Z" />
        </svg>
    )
}

export default Dots3Icon;

